import { useAppSelector } from "redux/hooks";
import { getUserState, getVerificationStatus } from "redux/slices/user-slice";
import { getVerificationRequestPath } from "utils/backend-path-builders";
import { postRequest } from "utils/httpClient";
import Presenter from "./presenter";
import { useHistory } from "react-router-dom";
import { IDENTITY_VERIFICATION_STATUS } from "interfaces/users-response";

export interface IdscanVerificationRequestBody {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  sendSms: boolean;
}

const Container = () => {
  const userId = useAppSelector(getUserState).id;
  const verificationStatus = useAppSelector(getVerificationStatus);
  const history = useHistory();

  // If user is approved they should not be able to verify again or else they will get
  // duplicate error. This will make sure they do not accidentally end up here.
  if (verificationStatus === IDENTITY_VERIFICATION_STATUS.APPROVED) {
    history.push("/");
  }

  const requestVerification = (
    verificationRequestBody: IdscanVerificationRequestBody
  ) => {
    return postRequest(getVerificationRequestPath(userId), {
      body: verificationRequestBody,
    });
  };
  return <Presenter sendRequest={requestVerification} history={history} />;
};

export default Container;
