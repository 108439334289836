import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Button, InputGroup, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { Form } from "react-bootstrap";
import OpenSansText from "components/custom-texts/open-sans-text";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import sharedModalStyles from "assets/shared-stylesheets/modal.module.css";
import { useEffect, useState } from "react";
import { sparketBlack, sparketLightGray } from "utils/constants";
import { fetchUser, getUserState } from "redux/slices/user-slice";
import { getRequest, postRequest, putRequest } from "utils/httpClient";
import {
  getUserPath,
  getValidatePotentialUsernamePath,
} from "utils/backend-path-builders";

interface Props {
  show: boolean;
  close: () => void;
}
const UsernameFormModal = ({ show, close }: Props) => {
  const group = useAppSelector(getGroupState);
  const primaryColor = group.settings.primary_color;
  const user = useAppSelector(getUserState);
  const initialUsername = user.user_name;

  const [callbackMessage, setCallbackMessage] = useState("");
  const [invalidUsername, setInvalidUsername] = useState(true);
  const [newUsername, setNewUsername] = useState(initialUsername);
  const [userToUpdate, setUserToUpdate] = useState({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    getRequest(getUserPath(), { skipIntegrationApi: true }).then((data) => {
      setUserToUpdate(data);
    });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (newUsername === initialUsername) {
        setCallbackMessage("");
        setInvalidUsername(false);
      } else if (newUsername === "") {
        setCallbackMessage(
          "Please choose a username. You cannot place a bet if you haven't entered a username!"
        );
        setInvalidUsername(true);
      } else {
        // check if alias is unique
        postRequest(getValidatePotentialUsernamePath(), {
          body: { user_name: newUsername },
        })
          .then(() => {
            setCallbackMessage(
              "The username " + newUsername + " is available!"
            );
            setInvalidUsername(false);
          })
          .catch((e) => {
            if (e.response === undefined || e.response.data === undefined) {
              setCallbackMessage(
                "Sorry, our servers cannot determine uniqueness."
              );
            } else if (e.response.data.error_code === 5000) {
              setCallbackMessage(
                "Username " +
                  newUsername +
                  " is taken. Please try another username."
              );
              setInvalidUsername(true);
            } else {
              setCallbackMessage(e.response.data.friendly_error_message);
            }
          });
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [newUsername, initialUsername]);

  return (
    <Modal show={show}>
      <Modal.Title
        style={{
          justifyContent: "space-between",
          backgroundColor: primaryColor,
        }}
        className={sharedModalStyles.modalTitle}
      >
        <div />
        <BlockyHeavyText>Please Choose a Username</BlockyHeavyText>
        <div />
      </Modal.Title>
      <Modal.Body
        className={sharedModalStyles.modalBody}
        style={{
          borderColor: primaryColor,
          backgroundColor: sparketBlack,
          color: "white",
        }}
      >
        <Form style={{ width: 300 }}>
          <BlockyMediumText style={{ marginBottom: 20, fontSize: 15 }}>
            This will show up on leaderboards when you win, so make sure to pick
            something good!
          </BlockyMediumText>
          <InputGroup>
            <Form.Control
              required
              isInvalid={invalidUsername}
              style={{ backgroundColor: sparketLightGray, width: "100%" }}
              placeholder="Username"
              onChange={(e) => {
                setNewUsername(e.target.value);
              }}
              value={newUsername}
            />
            <div
              style={
                invalidUsername
                  ? { color: "red", paddingBottom: 10 }
                  : { color: "green", paddingBottom: 10 }
              }
            >
              {callbackMessage}
            </div>
          </InputGroup>

          <BlockyMediumText style={{ fontSize: 14, paddingTop: 15 }}>
            You can edit your profile information by clicking the icon at the
            top right anytime.
          </BlockyMediumText>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                backgroundColor: sparketBlack,
                marginTop: 20,
                borderColor: primaryColor,
                color: "white",
              }}
              onClick={close}
            >
              <BlockyMediumText>Skip</BlockyMediumText>
            </Button>
            <div style={{ width: 10 }} />
            <Button
              style={{
                backgroundColor: primaryColor,
                border: "none",
                marginTop: 20,
                width: 77,
                color: "black",
              }}
              disabled={invalidUsername}
              onClick={() => {
                if (!userToUpdate) {
                  return;
                }

                const updatedUser = { ...userToUpdate, user_name: newUsername };
                putRequest(getUserPath(), {
                  body: updatedUser,
                }).then(() => {
                  dispatch(fetchUser());
                  close();
                });
              }}
            >
              <div>
                {false ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <BlockyHeavyText>Submit</BlockyHeavyText>
                )}
              </div>
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UsernameFormModal;
