import { Dispatch, SetStateAction } from "react";
import { Spinner } from "react-bootstrap";
import ReactCodeInput from "react-code-input";
import { sparketGold } from "utils/constants";
import { inputStyle, inputStyleInvalid } from "./styles";
import { useAppSelector } from "../../redux/hooks";
import { getGroupSecondaryColorOrDefault } from "../../redux/slices/group-slice";

interface Props {
  setCode: Dispatch<SetStateAction<string>>;
  setWrongCode: Dispatch<SetStateAction<boolean>>;
  wrongCode: boolean;
  code: string;
  loading: boolean;
}

const SixDigitCodeInput = ({
  setCode,
  setWrongCode,
  code,
  wrongCode,
  loading,
}: Props) => {
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactCodeInput
        className="sparket-code-input"
        inputStyle={inputStyle}
        inputStyleInvalid={inputStyleInvalid}
        fields={6}
        onChange={(value) => {
          setWrongCode(false);
          setCode(value);
        }}
        value={code}
        name="SparketCodeInput"
        type="number"
        inputMode="numeric"
        isValid={!wrongCode}
      />
      {loading && (
        <Spinner animation="border" size="sm" style={{ color: secondaryColor}} />
      )}
    </div>
  );
};
export default SixDigitCodeInput;
