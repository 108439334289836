import { VALID_PHONE_NUMBER_LENGTH } from "components/profile/two-factor-auth/setup-mfa-content";
import formatPhoneNumber from "components/profile/two-factor-auth/utils/format-phone-number";
import { useAuth } from "hooks/auth";
import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { sparketGold } from "utils/constants";
import { IdscanVerificationRequestBody } from "./container";
import { useAppSelector } from "../../redux/hooks";
import { getGroupSecondaryColorOrDefault } from "../../redux/slices/group-slice";

const RequestForm = ({
  setFirstName,
  firstName,
  lastName,
  setLastName,
  phone,
  setPhone,
  sendRequest,
}: any) => {
  const [loading, setLoading] = useState(false);
  const invalidPhone = phone.length !== VALID_PHONE_NUMBER_LENGTH;
  const formIsIncomplete: boolean =
    firstName === "" || lastName === "" || phone === "" || invalidPhone;

  const handleInput = (value: string) => {
    if (value.includes("+1 0")) {
      // this makes google autofill work correctly
      // TODO: allow phone numbers with other country codes?
      value = value.replace("+1 0", "");
    }
    const formattedNumber = formatPhoneNumber(value);
    setPhone(formattedNumber);
  };

  const send = async () => {
    setLoading(true);
    const regex = /[^A-Za-z0-9]/g;
    // idscan phone number format is "+15739992019"
    const formattedPhoneNumber = "+1" + phone.replace(regex, "");
    const requestBody: IdscanVerificationRequestBody = {
      phone: formattedPhoneNumber,
      firstName,
      lastName,
      sendSms: true,
      email,
    };
    try {
      await sendRequest(requestBody);
    } catch (error) {
      console.log("Verification request error.");
      setLoading(false);
    }
  };

  const auth = useAuth();
  const email = auth.getEmail();
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Text className="text-muted">
          <br></br>* All fields are required
          <br></br>
          <br></br>
        </Form.Text>
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="tel"
          value={phone}
          onChange={(e) => handleInput(e.target.value)}
          placeholder="(___) ___-____"
          disabled={loading}
        />
        <Form.Text className="text-muted">
          We will send a text to this number with further instructions
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          required
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
          disabled={loading}
        />
        <Form.Text className="text-muted">
          as it appears on your legal documents
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          value={lastName}
          onChange={(event) => {
            setLastName(event.target.value);
          }}
          disabled={loading}
        />
        <Form.Text className="text-muted">
          as it appears on your legal documents
        </Form.Text>
      </Form.Group>

      <Button
        disabled={formIsIncomplete || loading}
        onClick={send}
        style={{
          backgroundColor: secondaryColor,
          color: "black",
          border: "none",
          float: "right",
        }}
      >
        {loading && (
          <>
            <Spinner size="sm" animation="border" />
            &nbsp;
          </>
        )}
        Send SMS
      </Button>
    </Form>
  );
};

export default RequestForm;
