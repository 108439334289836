import { Nav, Tab } from "react-bootstrap";
import { Entry } from "interfaces/entry";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import SingleSelectionEntryList
  from "components/pool-details/entries/single-selection-entry-list/single-selection-entry-list";
import OrderedSelectionEntryList
  from "components/pool-details/entries/ordered-selection-entry-list/ordered-selection-entry-list";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import { useEffect, useState } from "react";
import BetForm from "./bet-form";
import { Bet } from "interfaces/bet";
import { isIntegratedApp } from "utils/httpClient";
import { useAuth } from "hooks/auth";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, requestToJoinGroup } from "redux/slices/group-slice";
import { ALLOWED, APPROVED, SKIP } from "utils/constants";
import JoinGroupModal from "home/join-group-button/join-group-modal";
import { showSignIn } from "redux/slices/login-modals-slice";
import useGeolocation from "hooks/useGeolocation";
import GeolocationStatus from "../geolocation/status-badge";
import GeolocationModal from "../geolocation/modal/modal";
import LoadingBetForm from "./loading-bet-form";
import { betTypeWin } from "interfaces/bet-type-name";
import { sortBetTypes } from "components/pool-details/bet-types";

interface EntryListProps {
  pool: PoolDetailsResponse;
  setCurrentPoolType: any;
  onSuccessfulBetPlaced: (bet: Bet) => void;
}

const EntryList = ({
  pool,
  setCurrentPoolType,
  onSuccessfulBetPlaced,
}: EntryListProps) => {
  const auth = useAuth();
  const [selectedEntry, setSelectedEntry] = useState<Entry>();
  const group = useAppSelector(getGroupState);
  const dispatch = useAppDispatch();
  const userHasJoinedGroup = group.status === APPROVED;
  const [showJoinGroupModal, setShowJoinGroupModal] = useState(false);
  const { geolocationStatus, checkStatus } = useGeolocation();
  const [showGeolocationModal, setShowGeolocationModal] = useState(false);
  const [isFetchingEntry, setIsFetchingEntry] = useState(false);

  const allowGeolocationBadge =
    group.settings.enable_geolocation && auth.signedIn && userHasJoinedGroup;

  const orderedBetTypes = sortBetTypes(pool.pool_types);

  useEffect(() => {
    if (geolocationStatus === ALLOWED) {
      setShowGeolocationModal(false);
    }
  }, [geolocationStatus]);

  const selectBet = async (entry: Entry) => {
    if (!entry) {
      // User unselected a contestant, hide the form
      setSelectedEntry(undefined);
      return;
    }
    const geoResult = await checkStatus();

    setShowGeolocationModal(true);
    if (userHasJoinedGroup) {
      if (![ALLOWED, SKIP].includes(geoResult.status)) {
        setShowGeolocationModal(true);
      }
    }

    if (!auth.signedIn) {
      // not logged in
      dispatch(showSignIn());
      return;
    }
    if (auth.signedIn && group.name && !userHasJoinedGroup) {
      // not in group
      setShowJoinGroupModal(true);
      return;
    }

    if (auth.signedIn || isIntegratedApp()) {
      setSelectedEntry(entry);
      setIsFetchingEntry(false);
    }
  };

  return (
    <div>
      {allowGeolocationBadge && (
        <GeolocationStatus
          status={geolocationStatus}
          showGeolocationModal={setShowGeolocationModal}
        />
      )}
      <Tab.Container
        id="bet-type-tabs"
        defaultActiveKey={orderedBetTypes[0]}
        onSelect={(tab) => {
          setCurrentPoolType(tab);
          setSelectedEntry(undefined);
        }}
      >
        {orderedBetTypes.length > 1 && (
          <Nav
            justify
            variant="pills"
            style={{
              paddingTop: 10,
              paddingBottom: 5,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            {orderedBetTypes.length > 1 &&
              orderedBetTypes.map((type) => {
                return (
                  <Nav.Item key={type} style={{ paddingRight: 5 }}>
                    <Nav.Link eventKey={type} style={{ textTransform: "capitalize", cursor: "pointer" }}>
                      <BlockyBoldText>{type}</BlockyBoldText>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
          </Nav>
        )}{" "}
        <Tab.Content style={{ position: "relative" }}>
          {orderedBetTypes.map((type) => {
            if (type === betTypeWin) {
              return (
                <Tab.Pane key={type} eventKey={type}>
                  <SingleSelectionEntryList
                    contestants={pool.contestants}
                    entries={pool.entries}
                    selectBet={selectBet}
                    winningContestantIds={pool.winner_contestant_ids_places ? (pool.winner_contestant_ids_places[1] || []) : []}
                    poolStatus={pool.status}
                    selectedEntry={selectedEntry}
                  />
                </Tab.Pane>
              );
            } else {
              return (
                <Tab.Pane key={type} eventKey={type}>
                  <OrderedSelectionEntryList
                    contestants={pool.contestants}
                    selectBet={selectBet}
                    poolType={type}
                    poolId={pool.id}
                    pool={pool}
                    setIsFetchingEntry={() => {
                      // calculate if we should show loading state or not
                      // const geoResult = await checkStatus();

                      if (userHasJoinedGroup && auth.signedIn) {
                        setIsFetchingEntry(true);
                      } else {
                        setIsFetchingEntry(false);
                      }
                    }}
                  />
                </Tab.Pane>
              );
            }
          })}
        </Tab.Content>
      </Tab.Container>
      {selectedEntry && (
        <BetForm
          entry={selectedEntry}
          pool={pool}
          onSuccessfulBetPlaced={onSuccessfulBetPlaced}
        />
      )}
      {isFetchingEntry && <LoadingBetForm />}
      <JoinGroupModal
        acceptAndJoin={() => {
          dispatch(requestToJoinGroup(group.id));
          setShowJoinGroupModal(false);
        }}
        show={showJoinGroupModal}
        close={() => setShowJoinGroupModal(false)}
      />
      {group.settings.enable_geolocation && auth.signedIn && (
        <GeolocationModal
          show={showGeolocationModal && group.settings.enable_geolocation}
          hide={() => {
            setShowGeolocationModal(false);
            checkStatus();
          }}
        />
      )}
    </div>
  );
};

export default EntryList;
