import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "utils/httpClient";
import { useAuth } from "./auth";
import { getBalancePath, getWalletPath } from "utils/backend-path-builders";
import { APPROVED } from "utils/constants";
import { getIntegratedAppState } from "redux/slices/integrated-app-slice";

const useWallet = () => {
  const auth = useAuth();
  const [balance, setBalance] = useState(0);
  const [walletId, setWalletId] = useState("");
  const [loading, setLoading] = useState(true);
  const group = useAppSelector(getGroupState);
  const groupId = group.id;
  const groupStatus = group.status;
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;

  const getWalletData = () => {
    if (isIntegratedApp) {
      postRequest(getBalancePath())
        .then((data) => {
          setBalance(data.total);
          setLoading(false);
          setWalletId(data.wallet_id);
        })
        .catch(() => {
          console.log("failed to fetch balance");
        });
    } else {
      if (auth.signedIn && groupId !== "" && groupStatus === APPROVED) {
        getRequest(getWalletPath(groupId)).then((data) => {
          setBalance(data.current_balance || 0);
          setWalletId(data.wallet_id);
          setLoading(false);
        });
      }
    }
  };

  useEffect(() => {
    getWalletData();
  }, [auth.signedIn, auth.user, groupId, groupStatus]);

  useEffect(() => {
    if (groupStatus === APPROVED) {
      setTimeout(getWalletData, 4000);
    }
  }, [groupStatus]);

  const formattedBalance = loading ? "$--.--" : formatAsCurrency(balance);

  return { balance, walletId, loading, formattedBalance };
};

export default useWallet;
