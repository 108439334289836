import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import epochToFormattedDateTime from "utils/formatter-utils/date-formatter";
import { Bet } from "interfaces/bet";
import {
  BET_STATUS,
  POOL_STATUS,
  sparketBlack,
  sparketDarkRed,
  sparketGold,
  sparketGreen,
} from "utils/constants";
import sharedStyles from "assets/shared-stylesheets/sparket-list-item.module.css";
import styles from "./styles.module.css";
import { useAppSelector } from "redux/hooks";
import { getGroupSecondaryColorOrDefault, getGroupState, getGroupStyles } from "redux/slices/group-slice";
import BlockyLightText from "components/custom-texts/blocky-light-text";
import { SetStateAction, Dispatch, useState } from "react";
import { SellBetButton, ListedBetButton } from "@wagerwire/embed/react";
import { getUserState } from "../../../redux/slices/user-slice";
import { isPoolClosed } from "utils/pool/is-pool-closed";

const getBetLabelInfo = (betStatus: number, poolStatus: number, secondaryColor: string) => {
  switch (betStatus) {
    case BET_STATUS.WIN:
      return { text: "Win", color: sparketGreen };
    case BET_STATUS.LOSS:
      return { text: "Loss", color: sparketDarkRed };
    case BET_STATUS.CREATED:
      if ([POOL_STATUS.PENDING, POOL_STATUS.OPEN].includes(poolStatus)) {
        return { text: "Open", color: "black" };
      } else {
        return { text: "In Progress", color: secondaryColor};
      }
    case BET_STATUS.SOLD:
      return { text: "Sold", color: "black" };
    case BET_STATUS.CANCELLED:
      return { text: "Cancelled", color: "black" };
    default:
      return { text: "In Progress", color: secondaryColor};
  }
};

const getStatusInfo = (bet: Bet) => {
  const poolStatus = bet.pool_status;
  const betStatus = bet.status;
  if (
    poolStatus === POOL_STATUS.CANCELLED ||
    bet.status === BET_STATUS.CANCELLED
  ) {
    return {
      label: "Cancelled",
      resultLabel: "Cancelled:",
      resultValue: +bet.amount,
    };
  }

  if (poolStatus === POOL_STATUS.OPEN && betStatus != BET_STATUS.SOLD) {
    return {
      resultLabel: "Est. Winnings:",
      resultValue: bet.payout,
    };
  }

  switch (betStatus) {
    case BET_STATUS.WIN:
      return {
        resultLabel: "Win:",
        resultValue: bet.payout,
      };
    case BET_STATUS.LOSS:
      return {
        resultLabel: "Loss:",
        resultValue:
          bet.ww_purchased_amount > 0 ? -bet.ww_purchased_amount : -bet.amount,
      };
    case BET_STATUS.SOLD:
      return {
        resultLabel: "Sold",
        resultValue: bet.ww_listed_amount,
      };
    default:
      return {
        resultLabel: "Est. Winnings:",
        resultValue: bet.payout,
      };
  }
};
interface MyBetItemProps {
  bet: Bet;
  setSelectedBet: Dispatch<SetStateAction<Bet | undefined>>;
}
const MyBetItem = ({ bet, setSelectedBet }: MyBetItemProps) => {
  const user = useAppSelector(getUserState);
  const group = useAppSelector(getGroupState);
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);
  const statusInfo = getStatusInfo(bet);
  const betLabelInfo = getBetLabelInfo(bet.status, bet.pool_status, secondaryColor);
  const isPoolCancelled = bet.pool_status === POOL_STATUS.CANCELLED;
  const isPoolLive = bet.pool_status === POOL_STATUS.LIVE;
  const isPoolOpen = bet.pool_status === POOL_STATUS.OPEN;
  const isBetCancelled = bet.status === BET_STATUS.CANCELLED;
  const cancelled = isPoolCancelled || isBetCancelled;
  const pointerEvents = cancelled ? "none" : "initial";
  let amount = bet.amount;
  if (bet.ww_purchased_amount > 0) {
    amount = bet.ww_purchased_amount;
  }

  // WW conditions
  const isSellable =
    group.settings.wager_wire &&
    bet.pool_ww_enabled &&
    isPoolLive &&
    bet.status === BET_STATUS.CREATED &&
    (bet.ww_listing_id === "" || bet.ww_status === "CANCELED");
  const isListed =
    group.settings.wager_wire &&
    bet.pool_ww_enabled &&
    isPoolLive &&
    bet.status === BET_STATUS.CREATED &&
    bet.ww_listing_id != "" &&
    bet.ww_status === "LISTED";
  // Example bet data in Sparket format
  const exampleSparketBet = {
    activity: bet.event_activity_name as any,
    bet_amount: amount,
    bet_created: new Date(bet.created).toISOString(),
    bet_id: bet.id,
    contestant_id: bet.pool_entry_id,
    contestant_name: bet.contestant_name,
    estimated_payout: bet.payout,
    event_id: bet.event_id,
    event_name: bet.event_name,
    event_starts: new Date(bet.event_start).toISOString(),
    group_color_hex: group.settings.primary_color,
    group_id: group.id,
    group_name: group.name,
    odds: bet.odds,
    pool_close: new Date(bet.pool_close).toISOString(),
    pool_id: bet.pool_id,
    pool_name: bet.pool_name,
    user_id: user.id,
  };
  // @ts-ignore
  const handleChildElementClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      onClick={() => setSelectedBet(bet)}
      style={{
        textDecoration: "none",
        color: sparketBlack,
        pointerEvents,
        cursor: "auto",
      }}
    >
      <div className={sharedStyles.itemContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 0.9,
            paddingTop: 7,
          }}
        >
          <div style={{ display: "flex", marginLeft: 10 }}>
            <BlockyHeavyText
              customStyles={{
                fontSize: 16,
              }}
            >
              {bet.contestant_name}
            </BlockyHeavyText>
          </div>
          <BlockyHeavyText style={{ marginLeft: 10 }}>
            {bet.event_name && bet.event_name + ": "}
            {bet.pool_name}
          </BlockyHeavyText>
          <div style={{ display: "flex" }}>
            <div
              style={{ flex: 1 }}
              className={sharedStyles.leftColumnContainer}
            >
              <div className={styles.closeContainer}>
                <BlockyBoldText className={styles.yourBetLabelText}>
                  {isPoolClosed(bet.pool_status) ? "Closed" : "Closes"}:
                </BlockyBoldText>
                <BlockyMediumText style={{ fontSize: 12 }}>
                  {epochToFormattedDateTime(bet.pool_close)}
                </BlockyMediumText>
                {isPoolOpen && bet.pool_ww_enabled && (
                  <BlockyMediumText
                    style={{ fontSize: 12, fontStyle: "italic" }}
                  >
                    &nbsp;(Sellable after close)
                  </BlockyMediumText>
                )}
              </div>
              <div className={styles.betAmountAndResultRowContainer}>
                <div className={styles.resultsItem}>
                  <BlockyBoldText className={styles.yourBetLabelText}>
                    Status:
                  </BlockyBoldText>
                  <BlockyBoldText
                    style={{ color: betLabelInfo.color }}
                    className={styles.betAmountText}
                  >
                    {betLabelInfo.text}
                  </BlockyBoldText>
                </div>
                <div className={styles.resultsItem}>
                  <BlockyBoldText className={styles.yourBetLabelText}>
                    Your Bet:
                  </BlockyBoldText>
                  <BlockyBoldText className={styles.betAmountText}>
                    {formatAsCurrency(amount)}
                  </BlockyBoldText>
                </div>
                <div className={styles.resultsItem}>
                  <BlockyBoldText className={styles.yourBetLabelText}>
                    {statusInfo.resultLabel}
                  </BlockyBoldText>
                  <BlockyBoldText
                    className={styles.betAmountText}
                    style={{ color: betLabelInfo.color }}
                  >
                    {formatAsCurrency(statusInfo.resultValue)}
                  </BlockyBoldText>
                </div>
              </div>
            </div>
            {(isSellable || isListed) && (
              <div style={{ padding: 5, minWidth: 126, alignSelf: "flex-end" }}>
                {/* WW sell button */}
                {isSellable && (
                  <div
                    onClick={(e) => handleChildElementClick(e)}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <SellBetButton
                      bet={exampleSparketBet}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: 10,
                        textTransform: "uppercase",
                      }}
                      groupColor={group.settings.primary_color}
                      groupId={group.id}
                    />
                  </div>
                )}
                {/* WW listed button */}
                {isListed && (
                  <div
                    onClick={(e) => handleChildElementClick(e)}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <ListedBetButton
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: 10,
                        textTransform: "uppercase",
                      }}
                      orderId={bet.ww_listing_id}
                      groupColor={group.settings.primary_color}
                      groupId={group.id}
                      children={""}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isPoolCancelled ? (
          <div
            style={{
              flexDirection: "column",
              marginLeft: 10,
            }}
            className={sharedStyles.rightColumnContainer}
          >
            <BlockyLightText
              style={{
                minWidth: 80,
                fontSize: 10,
                fontStyle: "italic",
                textAlign: "center",
              }}
            >
              This pool has been deleted
            </BlockyLightText>
          </div>
        ) : (
          <div
            className={sharedStyles.rightColumnContainer}
            style={{ backgroundColor: primaryColor }}
          >
            <BlockyHeavyText customStyles={{ fontSize: 14 }}>
              {">"}
            </BlockyHeavyText>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyBetItem;
