import { useEffect, useState } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getUserState, setEmailConfig } from "redux/slices/user-slice";
import {
  getConfigurableNotificationsPath,
  getUserPathNotifications,
} from "utils/backend-path-builders";
import { getRequest, putRequest } from "utils/httpClient";

interface NotificationConfigurationResponse {
  type: string;
  description: string;
}
const NotificationConfiguration = () => {
  const user = useAppSelector(getUserState);
  const currentEmailSettings = user.notifications.email as any;
  const dispatch = useAppDispatch();
  const [configurableNotifications, setConfigurableNotifications] = useState<
    NotificationConfigurationResponse[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRequest(getConfigurableNotificationsPath())
      .then((response: NotificationConfigurationResponse[]) => {
        setConfigurableNotifications(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const updateSettings = (key: string, value: boolean) => {
    const newEmailSettings = { ...currentEmailSettings };
    newEmailSettings[key] = value;
    const newUser = { ...user };
    newUser.notifications = { email: newEmailSettings };

    putRequest(getUserPathNotifications(), { body: newUser })
      .then((response) => {
        dispatch(setEmailConfig(response));
        toast.success("Email notification settings updated successfully");
      })
      .catch(() => {
        toast.error("Error updating notification settings");
      });
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Table size="sm">
      <tbody>
        {configurableNotifications.map((cn) => {
          if (currentEmailSettings[cn.type] === undefined) {
            return null;
          }
          const title = cn.description;
          const value = currentEmailSettings[cn.type] || false;
          return (
            <tr key={cn.type}>
              <td>{title}</td>
              <td>
                <Form.Switch
                  type="switch"
                  id="custom-switch"
                  label="Enable"
                  checked={value}
                  onChange={(e) => {
                    updateSettings(cn.type, e.target.checked);
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default NotificationConfiguration;
