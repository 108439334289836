import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getExternalIdModalState,
  close,
  updateExternalId,
} from "redux/slices/external-id-modal-slice";
import sharedModalStyles from "assets/shared-stylesheets/modal.module.css";
import { getGroupState } from "redux/slices/group-slice";
import { Form } from "react-bootstrap";
import OpenSansText from "components/custom-texts/open-sans-text";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { useEffect, useState } from "react";
import { FAILED, LOADING } from "utils/constants";

const ExternalIdModal = () => {
  const show = useAppSelector(getExternalIdModalState).open;
  const dispatch = useAppDispatch();
  const group = useAppSelector(getGroupState);
  const externalIdLabel = group.settings.external_id_label;
  const [externalId, setExternalId] = useState("");
  const { updatedStatus, failMessage } = useAppSelector(
    getExternalIdModalState
  );

  const primaryColor = group.settings.primary_color;

  useEffect(() => setExternalId(""), [show]);

  return (
    <Modal show={show}>
      <Modal.Title
        style={{
          justifyContent: "space-between",
          backgroundColor: primaryColor,
        }}
        className={sharedModalStyles.modalTitle}
      >
        <div />
        <BlockyHeavyText>{externalIdLabel}</BlockyHeavyText>
        <div />
      </Modal.Title>
      <Modal.Body
        className={sharedModalStyles.modalBody}
        style={{ borderColor: primaryColor }}
      >
        <OpenSansText style={{ marginBottom: 10, fontSize: 12 }}>
          Please enter your {externalIdLabel}
        </OpenSansText>
        <Form style={{ width: 270 }}>
          <Form.Group>
            <Form.Control
              onChange={(e) => setExternalId(e.target.value)}
              isInvalid={updatedStatus === FAILED}
            />

            <Form.Control.Feedback type="invalid">
              {failMessage}
            </Form.Control.Feedback>
          </Form.Group>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                backgroundColor: "white",
                marginTop: 20,
                borderColor: primaryColor,
                color: "black",
              }}
              onClick={() => {
                dispatch(close());
              }}
            >
              <BlockyMediumText>Skip</BlockyMediumText>
            </Button>
            <div style={{ width: 10 }} />
            <Button
              style={{
                backgroundColor: primaryColor,
                border: "none",
                marginTop: 20,
                width: 77,
                color: "black",
              }}
              disabled={externalId === ""}
              onClick={() => {
                dispatch(updateExternalId({ groupId: group.id, externalId }));
              }}
            >
              <div>
                {updatedStatus === LOADING ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <BlockyHeavyText>Submit</BlockyHeavyText>
                )}
              </div>
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ExternalIdModal;
