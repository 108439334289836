import {
  getUserPath,
  getUsersPersonalInformationPath,
} from "utils/backend-path-builders";
import { putRequest } from "utils/httpClient";

interface SubmitProfileInterface {
  firstName: string;
  lastName: string;
  country: string;
  postalCode: string;
  dateOfBirth: Date;
  username: string;
  status: string;
  bio: string;
  avatar: string;
  backdrop: string;
  userId: string;
  notifications: object;
}
const SubmitProfile = ({
  firstName,
  lastName,
  country,
  postalCode,
  dateOfBirth,
  username,
  status,
  bio,
  avatar,
  backdrop,
  userId,
  notifications,
}: SubmitProfileInterface) => {
  const iso = dateOfBirth.toISOString();
  const isoBirthday = iso.substr(0, iso.indexOf("T"));
  return putRequest(getUsersPersonalInformationPath(), {
    body: {
      first_name: firstName,
      last_name: lastName,
      country: country,
      postal_code: postalCode,
      date_of_birth: isoBirthday,
      // dummy fields to appease server,
      middle_name: "NA",
      middle_name_initial: "NA",
      street: "NA",
      street_2: "NA",
      city: "NA",
      phone_number: "123456789",
      gender: "NA",
    },
  }).then(() => {
    putRequest(getUserPath(), {
      body: {
        user_name: username,
        status: status,
        bio: bio,
        backdrop: backdrop,
        id: userId,
        avatar: avatar,
        notifications: notifications,
      },
    });
  });
};

export default SubmitProfile;
