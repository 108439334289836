import { useState, useEffect } from "react";
import { SelfLimitationsResponse } from "interfaces/self-limitations-response";
import { getSelfLimitationsPath } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";
import Limitation from "interfaces/limitation";

const useSelfExclusionsData = () => {
  const [dailyDepositCurrent, setDailyDepositCurrent] = useState<
    number | undefined
  >();
  const [dailyDepositNext, setDailyDepositNext] = useState<
    number | undefined
  >();
  const [dailyWagerCurrent, setDailyWagerCurrent] = useState<
    number | undefined
  >();
  const [dailyWagerNext, setDailyWagerNext] = useState<number | undefined>();
  const [weeklyDepositCurrent, setWeeklyDepositCurrent] = useState<
    number | undefined
  >();
  const [weeklyDepositNext, setWeeklyDepositNext] = useState<
    number | undefined
  >();
  const [weeklyWagerCurrent, setWeeklyWagerCurrent] = useState<
    number | undefined
  >();
  const [weeklyWagerNext, setWeeklyWagerNext] = useState<number | undefined>();
  const [monthlyDepositCurrent, setMonthlyDepositCurrent] = useState<
    number | undefined
  >();
  const [monthlyDepositNext, setMonthlyDepositNext] = useState<
    number | undefined
  >();
  const [monthlyWagerCurrent, setMonthlyWagerCurrent] = useState<
    number | undefined
  >();
  const [monthlyWagerNext, setMonthlyWagerNext] = useState<
    number | undefined
  >();

  const [exclusions, setExclusions] = useState<Limitation[]>();

  const [staleData, setStaleData] = useState(false);
  useEffect(() => {
    getRequest(getSelfLimitationsPath()).then(
      (selfLimitationsResponse: SelfLimitationsResponse) => {
        const betCurrent = selfLimitationsResponse.bet_current;
        setDailyWagerCurrent(betCurrent.daily?.limit_amount);
        setWeeklyWagerCurrent(betCurrent.weekly?.limit_amount);
        setMonthlyWagerCurrent(betCurrent.monthly?.limit_amount);

        const betNext = selfLimitationsResponse.bet_next;
        setDailyWagerNext(betNext.daily?.limit_amount);
        setWeeklyWagerNext(betNext.weekly?.limit_amount);
        setMonthlyWagerNext(betCurrent.monthly?.limit_amount);

        const depositCurrent = selfLimitationsResponse.deposit_current;
        setDailyDepositCurrent(depositCurrent.daily?.limit_amount);
        setWeeklyDepositCurrent(depositCurrent.weekly?.limit_amount);
        setMonthlyDepositCurrent(depositCurrent.monthly?.limit_amount);

        const depositNext = selfLimitationsResponse.deposit_next;
        setDailyDepositNext(depositNext.daily?.limit_amount);
        setWeeklyDepositNext(depositNext.weekly?.limit_amount);
        setMonthlyDepositNext(depositNext.monthly?.limit_amount);

        setExclusions(selfLimitationsResponse.exclusions);
      }
    );
    setStaleData(false);
  }, [staleData]);

  const refreshData = () => setStaleData(true);

  return {
    dailyDepositCurrent,
    dailyDepositNext,
    dailyWagerCurrent,
    dailyWagerNext,
    weeklyDepositCurrent,
    weeklyDepositNext,
    weeklyWagerCurrent,
    weeklyWagerNext,
    monthlyDepositCurrent,
    monthlyDepositNext,
    monthlyWagerCurrent,
    monthlyWagerNext,
    exclusions,
    refreshData,
  };
};

export default useSelfExclusionsData;
