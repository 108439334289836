import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { close } from "redux/slices/drawer-slice";
import { getGroupStyles } from "redux/slices/group-slice";
import {
  openPrivacyPolicy,
  openTermsOfService,
} from "redux/slices/legal-modal-slice";
import PoweredBySparket from "./powered-by-sparket";

const Footer = () => {
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  const dispatch = useAppDispatch();

  return (
    <>
      <PoweredBySparket />
      <div style={{ textAlign: "left" }}>
        <BlockyMediumText
          style={{
            color: "white",
            fontSize: 8,
          }}
        >
          Click to view our{" "}
          <span
            style={{ color: primaryColor, cursor: "pointer" }}
            onClick={() => {
              dispatch(openTermsOfService());
              dispatch(close());
            }}
          >
            Terms of Service
          </span>
          ,{" "}
          <span
            style={{ color: primaryColor, cursor: "pointer" }}
            onClick={() => {
              dispatch(openPrivacyPolicy());
              dispatch(close());
            }}
          >
            Privacy Policy
          </span>{" "}
          or to fill out our{" "}
          <span
            style={{ color: primaryColor, cursor: "pointer" }}
            onClick={() => {
              window.open("https://forms.gle/kzeNASvdQ65wXG8N8", "_blank");
            }}
          >
            Do Not Sell My Personal Information
          </span>{" "}
          form.
        </BlockyMediumText>
      </div>
    </>
  );
};

export default Footer;
