import { configureStore } from "@reduxjs/toolkit";
import drawerReducer from "./slices/drawer-slice";
import sparketTutorialReducer from "./slices/sparket-tutorial-slice";
import groupReducer from "./slices/group-slice";
import availableGroupsReducer from "./slices/available-groups-slice";
import legalModalReducer from "./slices/legal-modal-slice";
import externalIdModalReducer from "./slices/external-id-modal-slice";
import loginModalsReducer from "./slices/login-modals-slice";
import userSlice from "./slices/user-slice";
import contestPopupModalSlice from "./slices/contest-popup-modal-slice";
import integratedAppSlice from "./slices/integrated-app-slice";
import userSettingsSlice from "./slices/user-settings-slice";

const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    legalModal: legalModalReducer,
    sparketTutorial: sparketTutorialReducer,
    group: groupReducer,
    availableGroups: availableGroupsReducer,
    externalIdModal: externalIdModalReducer,
    loginModals: loginModalsReducer,
    user: userSlice,
    userSettings: userSettingsSlice,
    contestPopupModal: contestPopupModalSlice,
    integratedApp: integratedAppSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
