import { getRequest } from "utils/httpClient";
import { Button } from "react-bootstrap";
import { LeaderboardResponse } from "../../interfaces/leaderboard/leaderboard-response";
import { useEffect, useState } from "react";
import LoadingSpinner from "components/loading-spinner.tsx";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import styles from "./styles.module.css";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, getGroupStyles } from "redux/slices/group-slice";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Fullscreen } from "react-bootstrap-icons";
import FullscreenHeader from "./full-screen-header";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import LeaderTable from "./leader-table";
import CallToActionFooter from "./full-screen-footer/call-to-action-footer";
import { openContestTerms } from "redux/slices/legal-modal-slice";
import ContestFilter from "./contest-filter";
import { Contest } from "interfaces/leaderboard/contest";
import { getLeaderboardDataPath } from "utils/backend-path-builders";
import { Phase } from "interfaces/leaderboard/phase";

const Leaderboard = () => {
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  const [loading, setLoading] = useState(true);
  const [showContestPopup, setShowContestPopup] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardResponse>(
    {} as LeaderboardResponse
  );
  const group = useAppSelector(getGroupState);
  const groupId = group.id;
  const handle = useFullScreenHandle();
  const fullScreenActive = handle.active;
  const enterFullScreen = handle.enter;
  const { isMobile } = useDeviceDimensions();
  const [event, setEvent] = useState<any>();
  const [contest, setContest] = useState<Contest>();
  const [phase, setPhase] = useState<Phase>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!groupId) {
      return;
    }
    setLoading(true);
    getRequest(getLeaderboardDataPath(), {
      skipIntegrationApi: true,
      queryParams: {
        currency: group.currency_code || "skc",
        page_size: 100,
        "group-id": groupId,
        ...(event?.id && { "event-id": event?.id }),
        ...(contest?.id && { "contest-id": contest?.id }),
        ...(phase?.id && { "phase-id": phase?.id }),
      },
    })
      .then((data) => {
        setLeaderboardData(data as LeaderboardResponse);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [groupId, event?.id, contest?.id, phase?.id]);

  const leaderboardList = leaderboardData.user_list || [];
  const leaderboardUser = leaderboardData.user_entry;

  const contestPopupEnabled = group.settings.contest_terms_url;
  return (
    <FullScreen handle={handle} className={styles.fullScreen}>
      {fullScreenActive && <FullscreenHeader />}
      <>
        <div>
          <div style={{ backgroundColor: primaryColor }}>
            <div className={styles.titleRow}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <BlockyHeavyText
                  customStyles={{
                    fontSize: 22,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Leaderboard
                </BlockyHeavyText>
                {fullScreenActive &&
                  [event?.label, contest?.name, phase?.name]
                    .filter((string) => string !== undefined)
                    .map((filterLabel) => {
                      return (
                        <BlockyHeavyText
                          key={filterLabel}
                          customStyles={{
                            fontSize: 22,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          &nbsp;-&nbsp;{filterLabel}
                        </BlockyHeavyText>
                      );
                    })}
              </div>

              {!fullScreenActive && (
                <div style={{ display: "flex" }}>
                  {!isMobile && (
                    <Button
                      style={{
                        backgroundColor: "var(--sparket-black)",
                        border: "none",
                        color: "white",
                      }}
                      onClick={enterFullScreen}
                    >
                      <Fullscreen size={12} /> Fullscreen
                    </Button>
                  )}
                  {contestPopupEnabled && (
                    <>
                      <div style={{ marginLeft: 5 }} />
                      <Button
                        style={{
                          backgroundColor: "var(--sparket-black)",
                          border: "none",
                          color: "white",
                        }}
                        onClick={() => {
                          dispatch(openContestTerms());
                        }}
                      >
                        View Contest Terms
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
            <div style={{ display: fullScreenActive ? "none" : "initial" }}>
              <hr style={{ marginTop: 0, marginBottom: 0 }} />
              <div
                style={{ backgroundColor: primaryColor }}
                className={styles.filterRow}
              >
                <ContestFilter
                  setPhase={setPhase}
                  phase={phase}
                  setContest={setContest}
                />
              </div>
            </div>
          </div>
          <>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                {leaderboardList.length > 0 ? (
                  <>
                    <LeaderTable
                      fullScreen={fullScreenActive}
                      leaderboardUser={leaderboardUser}
                      leaderboardList={leaderboardList}
                    />
                    <div className={styles.totalContestants}>
                      <BlockyBoldText>Out of </BlockyBoldText>
                      <BlockyHeavyText
                        style={{
                          marginLeft: 3,
                          marginRight: 3,
                          color: primaryColor,
                        }}
                      >
                        {leaderboardData.total_contestants}
                      </BlockyHeavyText>
                      <BlockyBoldText> contestants</BlockyBoldText>
                    </div>
                  </>
                ) : (
                  <BlockyHeavyText
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 15,
                    }}
                  >
                    No results yet, please check back later.
                  </BlockyHeavyText>
                )}
              </>
            )}
          </>
        </div>
      </>
      {fullScreenActive && <CallToActionFooter />}
    </FullScreen>
  );
};

export default Leaderboard;
