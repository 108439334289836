import { sparketGold, sparketBlack } from "utils/constants";
import styles from "./styles.module.scss";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { Button, Spinner } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useAuth } from "hooks/auth";
import { Auth } from "aws-amplify";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { close } from "redux/slices/login-modals-slice";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import formatPhoneNumber from "./utils/format-phone-number";
import SixDigitCodeInput from "components/six-digit-code-input";
import { getGroupSecondaryColorOrDefault } from "../../../redux/slices/group-slice";

export const VALID_PHONE_NUMBER_LENGTH = 14;

const SetupMfaContent = () => {
  const auth = useAuth();
  const user = auth.user;
  const [phoneNumber, setPhoneNumber] = useState(
    formatPhoneNumber(user.attributes?.phone_number?.slice(2) || "")
  );
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [wrongCode, setWrongCode] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleInput = (value: string) => {
    const formattedNumber = formatPhoneNumber(value);
    setPhoneNumber(formattedNumber);
  };

  const submitPhoneNumber = async () => {
    if (phoneNumber.length !== VALID_PHONE_NUMBER_LENGTH) {
      return;
    }
    setLoading(true);
    const regex = /[^A-Za-z0-9]/g;
    const countryCode = "+1";
    const formattedNumber = countryCode + phoneNumber.replace(regex, "");
    await Auth.updateUserAttributes(user, {
      phone_number: formattedNumber,
    }); // update phone number in cognito
    await Auth.verifyCurrentUserAttribute("phone_number"); // send code to user
    setVerificationCodeSent(true);
    setLoading(false);
  };

  useEffect(() => {
    async function confirmAuth() {
      if (code.length === 6) {
        setLoading(true);
        try {
          await auth.verifyPhoneNumber(code);
          dispatch(close());
        } catch (error) {
          if ((error as any).name === "CodeMismatchException") {
            setWrongCode(true);
            setCode("");
          }
        } finally {
          setLoading(false);
        }
      }
    }

    confirmAuth();
  }, [code]);

  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  if (verificationCodeSent) {
    return (
      <>
        <div>
          <Button
            style={{
              backgroundColor: secondaryColor,
              border: "none",
              color: sparketBlack,
            }}
            onClick={() => setVerificationCodeSent(false)}
          >
            <BlockyHeavyText>Back</BlockyHeavyText>
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <BlockyHeavyText style={{ color: secondaryColor, fontSize: 20 }}>
            Enter Code
          </BlockyHeavyText>
          {phoneNumber && (
            <BlockyHeavyText style={{ color: "white", fontSize: 12 }}>
              We sent a code to +1 {phoneNumber}
            </BlockyHeavyText>
          )}

          <SixDigitCodeInput
            {...{ code, wrongCode, setWrongCode, setCode, loading }}
          />
        </div>
        <div></div>
      </>
    );
  }

  return (
    <>
      <div className={styles.titleContent}>
        <BlockyBoldText style={{ color: secondaryColor, fontSize: 25 }}>
          Please enter your phone number
        </BlockyBoldText>
        <BlockyMediumText style={{ color: "white" }}>
          We will text you a code to verify that you are who you say you are
        </BlockyMediumText>
      </div>
      <div style={{ justifySelf: "center", alignSelf: "center" }}>
        <div>
          <Form>
            <Form.Group style={{ display: "flex" }}>
              <BlockyBoldText
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  paddingRight: 12,
                }}
              >
                +1
              </BlockyBoldText>
              <Form.Control
                value={phoneNumber}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault();
                    submitPhoneNumber();
                  }
                }}
                onChange={(e) => handleInput(e.target.value)}
                placeholder="(___) ___-____"
              />
            </Form.Group>
          </Form>
        </div>
        <Button
          style={{
            backgroundColor: secondaryColor,
            border: "none",
            color: sparketBlack,
            width: "100%",
            height: 45,
            marginTop: 10,
          }}
          disabled={phoneNumber.length !== VALID_PHONE_NUMBER_LENGTH}
          onClick={submitPhoneNumber}
        >
          <div>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BlockyHeavyText>Submit</BlockyHeavyText>
            )}
          </div>
        </Button>
      </div>
      <div></div>
    </>
  );
};

export default SetupMfaContent;
