import * as storage from "utils/local-storage";
import { GROUP_SLUG, TOKEN, USER_ID } from "./constants";
import {getPrefixPath} from "./backend-path-builders";

const headers = () => {
  return {
    "x-user-id": storage.local.getItem(USER_ID) || "",
    "x-login-token": storage.local.getItem(TOKEN) || "",
    "x-client-id": storage.local.getItem(GROUP_SLUG) || "",
  };
};

const groupSlug = () => {
  return "/" + storage.local.getItem(GROUP_SLUG);
};

export const integratedGet = (path: string, options: any) => {
  const url = process.env.REACT_APP_BACKEND_URL + getPrefixPath() + groupSlug() + path;

  return fetch(url, {
    method: "GET",
    headers: headers(),
  }).then((res) => res.json());
};

export const integratedPost = (path: string, options: any, retries: number = 3) => {
  const url = process.env.REACT_APP_BACKEND_URL + getPrefixPath() + groupSlug() + path;

  const attemptRequest = async (attempt: number): Promise<any> => {
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headers(),
        body: JSON.stringify(options?.body),
      });

      if (res.ok) {
        return res.json();
      } else {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
    } catch (error) {
      if (attempt < retries) {
        console.log(`Retrying... Attempt ${attempt + 1}`);
        return attemptRequest(attempt + 1);
      } else {
        throw new Error(`Max retries reached (${retries}). Last error: ${error}`);
      }
    }
  };

  return attemptRequest(1); // Start with the first attempt
};

export const integratedPut = (path: string, options: any) => {
  const url = process.env.REACT_APP_BACKEND_URL + getPrefixPath() + groupSlug() + path;

  return fetch(url, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(options?.body),
  }).then((res) => res.json());
};
