import { Mixpanel } from "hooks/mixPanel";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";

interface Props {
  styles?: object;
}
const AdBanner = ({ styles = {} }: Props) => {
  const ad_banner_settings = useAppSelector(getGroupState).settings.ad_banner;

  if (!ad_banner_settings || !ad_banner_settings.enabled) {
    return <></>;
  }

  return (
    <div
      style={{
        height: "50%",
        alignItems: "center",
        display: "flex",
        ...styles,
      }}
    >
      <a
        onClick={() => {
          Mixpanel.track("Ad Banner Clicked", {
            imageUrl: ad_banner_settings.image_url,
            link: ad_banner_settings.link_url,
          });
        }}
        href={ad_banner_settings.link_url}
        target="_blank"
      >
        <img width="100%" src={ad_banner_settings.image_url} />
      </a>
    </div>
  );
};

export default AdBanner;
