import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { useAppSelector } from "redux/hooks";
import { getGroupState, getGroupStyles } from "redux/slices/group-slice";

const FullscreenHeader = () => {
  const { logo_url } = useAppSelector(getGroupStyles);
  const groupName = useAppSelector(getGroupState).name;

  return (
    <div
      style={{
        height: 100,
        backgroundColor: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        padding: 10,
      }}
    >
      {logo_url ? (
        <img src={logo_url} height={75} />
      ) : (
        <BlockyHeavyText style={{ fontSize: 50 }}>{groupName}</BlockyHeavyText>
      )}
    </div>
  );
};
export default FullscreenHeader;
