import { useEffect, useState } from "react";
import ConfirmResultsRepresenter from "./representer";
import { getRequest } from "utils/httpClient";
import { Pool } from "interfaces/pool";
import LoadingSpinner from "components/loading-spinner.tsx";
import Headroom from "react-headroom";
import Header from "components/header";
import { Alert } from "react-bootstrap";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { getPoolsPath } from "utils/backend-path-builders";
import { POOL_STATUS_PARAM } from "utils/constants";

const ConfirmResultsContainer = () => {
  const groupId = useAppSelector(getGroupState).id;
  const [pools, setPools] = useState<Pool[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!groupId) {
      return;
    }
    const path = getPoolsPath(groupId);
    getRequest(path, {
      queryParams: {
        status: POOL_STATUS_PARAM.LIVE,
        pg: 1,
        page_size: 10000,
        sortBy: "weight:desc,close:desc"
      },
    })
      .then((data) => {
        setPools(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [groupId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const text =
    pools && pools.length >= 1
      ? "Be the first to confirm the results of a pool. This isn't a wager but will earn you exclusive profile badges in the future."
      : "There are no pools to confirm. Check back while events are in progress.";

  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      <Alert variant="warning">{text}</Alert>

      <ConfirmResultsRepresenter pools={pools} />
    </>
  );
};

export default ConfirmResultsContainer;
