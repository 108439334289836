import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import Header from "components/header";
import LoadingSpinner from "components/loading-spinner.tsx";
import { Bet } from "interfaces/bet";
import { useEffect, useState } from "react";
import Headroom from "react-headroom";
import { getRequest } from "utils/httpClient";
import MyBetItem from "./my-bet-item";
import styles from "./styles.module.css";
import MultipleGroupsTooltip from "./multiple-groups-tooltip";
import { useAppSelector } from "redux/hooks";
import { getAvailableGroups } from "redux/slices/available-groups-slice";
import { getGroupState } from "redux/slices/group-slice";
import {
  getBetDetailsPath,
  getMyBetsPath,
  integratedGetBetDetails,
  integratedMyBets,
} from "utils/backend-path-builders";
import Modal from "./modal";
import { getIntegratedAppState } from "redux/slices/integrated-app-slice";

const MyBets = (props: any) => {
  const [bets, setBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const availableGroups = useAppSelector(getAvailableGroups);
  const hasOtherGroups = availableGroups.length > 1;
  const group = useAppSelector(getGroupState);
  const [selectedBet, setSelectedBet] = useState<Bet | undefined>();
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;

  useEffect(() => {
    const id: string = props?.match?.params?.id;

    if (props?.location?.state) {
      setSelectedBet(props.location.state.bet);
      setLoading(false);
    } else if (id) {
      const path = isIntegratedApp
        ? integratedGetBetDetails(id)
        : getBetDetailsPath(group.id, id);
      getRequest(path).then((data) => {
        setSelectedBet(data);
        setLoading(false);
      });
    }
  }, [props?.match?.params.id, props?.location?.state]);

  useEffect(() => {
    if (!group.id) {
      return;
    }
    const path = isIntegratedApp ? integratedMyBets() : getMyBetsPath(group.id);
    getRequest(path).then((data) => {
      if (data.length >= 1) {
        const sortedBets = data.sort((a: Bet, b: Bet) => b.created - a.created);
        setBets(sortedBets);
      }
      setLoading(false);
    });
  }, [group.id]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <>
        <Headroom>
          <Header />
        </Headroom>
        {bets !== null && bets.length >= 1 ? (
          <>
            <div className={styles.titleRow}>
              <BlockyHeavyText customStyles={{ fontSize: 22, marginRight: 10 }}>
                My Picks
              </BlockyHeavyText>
              {hasOtherGroups && <MultipleGroupsTooltip />}
            </div>
            {bets.map((bet, index) => {
              return (
                <MyBetItem
                  setSelectedBet={setSelectedBet}
                  key={index}
                  bet={bet}
                />
              );
            })}
          </>
        ) : (
          <div className={styles.noBetsTextContainer}>
            <BlockyHeavyText>You haven't made any picks yet</BlockyHeavyText>
          </div>
        )}
      </>
      <Modal bet={selectedBet} hide={() => setSelectedBet(undefined)} />
    </>
  );
};

export default MyBets;
