import Dinero from "dinero.js";
import store from "redux/store";
import { DOLLAR_SIGN, getGroupCurrencySymbol } from "redux/slices/group-slice";

export const formatAsCurrency = (amount: number, useDollars?: boolean) => {
  const state = store.getState();
  let currencySymbol;
  currencySymbol = getGroupCurrencySymbol(state);

  if (useDollars) {
    currencySymbol = DOLLAR_SIGN;
  }

  return Dinero({ amount: Math.floor(amount * 100) })
    .toFormat()
    .replace("$", currencySymbol);
};

export const formattedCurrencyToNumber = (value: string): number => {
  return parseFloat(value.replace(/[^0-9.]+/g, ""));
};

export const formatAsDollars = (amount: number) =>
  formatAsCurrency(amount, true);
