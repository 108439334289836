import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { setIntegratedState } from "redux/slices/integrated-app-slice";
import {getInitializeGamePath, getPrefixPath} from "utils/backend-path-builders";
import useQueryParam, { queryParamsToObject } from "utils/useQueryParams";
import { IS_INTEGRATED_APP, USER_ID, TOKEN, GROUP_SLUG } from "utils/constants";
import * as storage from "utils/local-storage";
import { postRequest } from "utils/httpClient";
import { setApprovedGroupStatus } from "redux/slices/group-slice";

const InitializeIntegrationApp = (groupSlug: any) => {
  const location = useLocation();
  const isIntegratedApp = location.pathname
    .split("/")
    .includes("integration-app");
  const dispatch = useAppDispatch();
  const queryParams = useQueryParam();

  if (groupSlug) {
    storage.local.setItem(GROUP_SLUG, groupSlug);
  }

  if (isIntegratedApp) {
    storage.local.setItem(IS_INTEGRATED_APP, "true");
  }

  useEffect(() => {
    if (!isIntegratedApp) return;

    const userId = queryParams.get("user_id");
    const token = queryParams.get("login_token");

    const initGame = async () => {
      const queryParamsJson = queryParamsToObject(queryParams);

      const res = await fetch(
        process.env.REACT_APP_BACKEND_URL +
          getPrefixPath() + '/' +
          groupSlug +
          getInitializeGamePath(),
        {
          method: "POST",
          headers: {
            "x-user-id": userId || "",
            "x-login-token": token || "",
          },
          body: JSON.stringify(queryParamsJson),
        }
      );
      const json = await res.json();

      dispatch(
        setIntegratedState({
          isIntegratedApp: true,
          userId: json.external_user_id,
          token: json.token,
        })
      );
      dispatch(setApprovedGroupStatus());
    };

    initGame();
  }, [isIntegratedApp]);
};

export default InitializeIntegrationApp;
