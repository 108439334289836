import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Entry } from "interfaces/entry";
import sharedStyles from "assets/shared-stylesheets/contestant-list-item.module.css";
import styles
  from "components/pool-details/entries/single-selection-entry-list/single-selection-entry-list-styles.module.css";
import entryItemStyles from "../entry-item-styles.module.css";
import { useRef } from "react";
import { sparketGold } from "utils/constants";
import { getGroupSecondaryColorOrDefault, getGroupStyles } from "redux/slices/group-slice";
import { useAppSelector } from "redux/hooks";

interface OpenEntryItemProps {
  item: Entry;
  selectBet: React.Dispatch<React.SetStateAction<Entry>>;
  setHeight?: React.Dispatch<React.SetStateAction<number>>;
  isSelected?: boolean;
  projectedWin: number;
}

const SingleSelectionOpenEntryItem = ({
                                        item,
                                        selectBet,
                                        setHeight,
                                        projectedWin,
                                        isSelected = false,
                                      }: OpenEntryItemProps) => {
  const ref = useRef(null);
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  return (
    <div
      id="div-OpenEntryItem-clickEntryItem"
      onClick={() => {
        selectBet(item);
        setHeight && setHeight((ref.current as any)?.clientHeight);
      }}
    >
      <div
        ref={ref}
        className={`${sharedStyles.itemContainer} ${entryItemStyles.container}`}
        style={{ border: isSelected ? `3px solid ${secondaryColor}` : "" }}
      >
        <div className={`${sharedStyles.itemLeftColumnContainer} ${styles.singleSelectionItemLeftColumnContainer}`}>
          <BlockyHeavyText
            className={entryItemStyles.titleText}
          >{`${item.contestant_name}`}</BlockyHeavyText>
          <div className={sharedStyles.inline}>
            <BlockyHeavyText
              className={`${sharedStyles.activeBetsText} ${entryItemStyles.labelText}`}
            >
              Projected Win:&nbsp;
            </BlockyHeavyText>
            <BlockyHeavyText className={entryItemStyles.oddsText}>
              {(projectedWin * 100).toFixed(1)}%
            </BlockyHeavyText>
          </div>
          <div className={sharedStyles.inline}>
            <BlockyHeavyText className={entryItemStyles.labelText}>
              Win Odds:&nbsp;
            </BlockyHeavyText>
            <BlockyHeavyText
              className={entryItemStyles.oddsText}
            >{`${item.odds.toFixed(1)}x`}</BlockyHeavyText>
          </div>
        </div>
        <div
          className={styles.singleSelectionItemRightColumnContainer}
          style={{ backgroundColor: primaryColor }}
        >
          <BlockyHeavyText>{">"}</BlockyHeavyText>
        </div>
      </div>
    </div>
  );
};

export default SingleSelectionOpenEntryItem;
