import { useAuth } from "hooks/auth";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./header-styles.module.scss";
import defaultImg from "assets/profile-img-upload.png";
import useWallet from "hooks/useWallet";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import getVerificationStatusIcon from "components/profile/identity-verification-info/getVerificationStatusIcon";
import { useAppSelector } from "redux/hooks";
import { getUserState, getVerificationStatus } from "redux/slices/user-slice";
import { isIntegratedApp } from "utils/httpClient";

const UserButton = () => {
  const auth = useAuth();
  const user = useAppSelector(getUserState);
  const verificationStatus = useAppSelector(getVerificationStatus);
  const [imgSrc, setImgSrc] = useState(defaultImg);
  const { formattedBalance } = useWallet();

  useEffect(() => {
    if (user.avatar) {
      setImgSrc(user.avatar);
    }
  }, [user.avatar]);

  const IdentityVerificationStatusIcon = getVerificationStatusIcon(
    verificationStatus
  );

  if (auth.signedIn) {
    return (
      <div className={styles.profilePicContainer}>
        <div style={{ position: "relative", marginBottom: 3 }}>
          <Link
            to="/profile"
            style={{ pointerEvents: isIntegratedApp() ? "none" : "initial" }}
          >
            <img alt="" className={styles.profilePic} src={imgSrc}></img>
          </Link>
          {IdentityVerificationStatusIcon && (
            <IdentityVerificationStatusIcon
              width={20}
              height={20}
              style={{
                position: "absolute",
                right: -7,
                bottom: -7,
                border: "4px solid black",
                borderRadius: 50,
              }}
            />
          )}
        </div>
        <BlockyBoldText style={{ color: "white", fontSize: 12 }}>
          {formattedBalance}
        </BlockyBoldText>
      </div>
    );
  } else {
    return <div style={{ width: 25 }} />;
  }
};

export default UserButton;
