import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Entry } from "interfaces/entry";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";
import { CheckCircle } from "react-bootstrap-icons";
import entryItemStyles from "components/pool-details/entries/entry-item-styles.module.css";
import { MouseEventHandler } from "react";
import { sparketGold } from "utils/constants";
import { useAppSelector } from "../../../redux/hooks";
import { getGroupSecondaryColorOrDefault } from "../../../redux/slices/group-slice";

interface Props {
  item: Entry;
  confirmed: boolean;
  projectedWin: number;
  hasActionButton?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const SingleSelectionLiveEntryItem = ({ item, confirmed, projectedWin }: Props) => {
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);
  const backgroundColor = confirmed ? secondaryColor : "lightgray";
  return (
    <div className={styles.itemContainer} style={{ backgroundColor }}>
      <div className={styles.leftColumnContainer}>
        <div className={`${styles.inline} ${entryItemStyles.titleText}`}>
          {confirmed && (
            <CheckCircle size={12} style={{ color: "black", marginRight: 4 }} />
          )}
          <BlockyHeavyText>{`${item.contestant_name}`}</BlockyHeavyText>
        </div>
        <div className={styles.inline}>
          <BlockyHeavyText
            className={`${styles.activeBetsText} ${entryItemStyles.labelText}`}
          >
            Projected Win:&nbsp;
          </BlockyHeavyText>
          <BlockyHeavyText className={entryItemStyles.oddsText}>
            {(projectedWin * 100).toFixed(1)}%
          </BlockyHeavyText>
        </div>
      </div>
      <div className={styles.middleColumnContainer}>
        <div className={styles.inline}>
          <BlockyHeavyText className={entryItemStyles.labelText}>
            Odds:&nbsp;
          </BlockyHeavyText>
          <BlockyHeavyText
            className={entryItemStyles.oddsText}
          >{`${item.odds.toFixed(2)}x`}</BlockyHeavyText>
        </div>
      </div>
    </div>
  );
};

export default SingleSelectionLiveEntryItem;
