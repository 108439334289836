import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Modal } from "react-bootstrap";
import sharedModalStyles from "assets/shared-stylesheets/modal.module.css";
import { Bet } from "interfaces/bet";
import { sparketBlack } from "utils/constants";
import Content from "./content";
import ConfettiExplosion from "react-confetti-explosion";

interface Props {
  bet: Bet;
  close: () => void;
}

const SuccessfulBetModal = ({ bet, close }: Props) => {
  return (
    <div>
      <Modal onHide={close} style={{ borderRadius: 20 }} show centered>

        <Modal.Title
          style={{
            justifyContent: "space-between",
          }}
          className={sharedModalStyles.modalTitle}
        >
          <div />
          <BlockyHeavyText>Pick Confirmed</BlockyHeavyText>
          <div />
        </Modal.Title>
        <Modal.Body style={{ backgroundColor: sparketBlack }}>
          <Content bet={bet} />
        </Modal.Body>
      </Modal>
      <ConfettiExplosion zIndex={1001} particleCount={400} duration={3000} width={1500} style={{
        position: "absolute",
        top: "30%",
        left: "50%"
      }} />
    </div>
  );
};

export default SuccessfulBetModal;
