import sparketTutorial3 from "assets/sparket-tutorial/sparket-tutorial-3.png";
import BlockyBlockyMediumText from "components/custom-texts/blocky-medium-text";
import styles from "./styles.module.css";

const PageOneContent = () => {
  return (
    <div className={styles.pageContentContainer}>
      <BlockyBlockyMediumText className={styles.topText}>
        Now, let’s say a <span className={styles.bold}>new user </span> makes a {" "}
        <span className={styles.bold}>$100</span> pick on{" "}
        <span className={styles.bold}>RR </span>to bring his total to{" "}
        <span className={styles.bold}>$200 </span>and the pool total to{" "}
        <span className={styles.bold}>$400</span>.
      </BlockyBlockyMediumText>
      <img className="d-block w-100" src={sparketTutorial3} alt="First slide" />
      <BlockyBlockyMediumText className={styles.bottomText}>
        <span className={styles.bold}>RR odds would lower</span> and{" "}
        <span className={styles.bold}>GF odds would rise</span> to 2x ($400 /
        $200 minus any commission)
      </BlockyBlockyMediumText>
    </div>
  );
};

export default PageOneContent;
