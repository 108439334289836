import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UsersResponse, {
  IDENTITY_VERIFICATION_STATUS,
} from "interfaces/users-response";
import { getUserPath } from "utils/backend-path-builders";
import {
  FAILED,
  IDLE,
  IS_INTEGRATED_APP,
  LOADING,
  SUCCEEDED,
} from "utils/constants";
import { getRequest, isIntegratedApp } from "utils/httpClient";
import { RootState } from "../store";
import * as storage from "utils/local-storage";

interface UserState extends UsersResponse {
  fetchUserState: "idle" | "loading" | "succeeded" | "failed";
}

export const initialState: UserState = {
  avatar: "",
  bio: "",
  created_at: "",
  id: "",
  last_login: "",
  last_seen: "",
  password_last_changed: "",
  status: "",
  super_admin: false,
  user_name: "",
  validation_status: IDENTITY_VERIFICATION_STATUS.NOT_STARTED,
  fetchUserState: IDLE,
  validation_request_id: "",
  notifications: { email: {} },
};

export const fetchUser = createAsyncThunk("user/fetch", async () => {
  if (isIntegratedApp()) {
    return;
  }
  const path = getUserPath();
  const response = await getRequest(path, { skipIntegrationApi: true });
  return response;
});

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEmailConfig: (state, action) => {
      state.notifications = action.payload.notifications;
    },
    clearUserState: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(fetchUser.pending, (state, _) => {
      state.fetchUserState = LOADING;
    });
    builder.addCase(fetchUser.fulfilled, (_, action) => {
      return { ...action.payload, fetchUserState: SUCCEEDED };
    });
    builder.addCase(fetchUser.rejected, (state, _) => {
      state.fetchUserState = FAILED;
    });
  },
});

export const getUserState = (state: RootState) => {
  return state.user;
};

export const getVerificationStatus = (state: RootState) => {
  return getUserState(state).validation_status;
};

export const { setEmailConfig, clearUserState } = slice.actions;

export default slice.reducer;
