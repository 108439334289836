import { useEffect, useState } from "react";
import { useAuth } from "hooks/auth";
import { useAppDispatch } from "redux/hooks";
import { close } from "redux/slices/login-modals-slice";

// The purpose of this hook is to DRY up sign in and sign up pages.
const useVerificationCode = () => {
  const auth = useAuth();
  const [code, setCode] = useState("");
  const [wrongCode, setWrongCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [email, onChangeEmail] = useState("");
  const [password, onChangePassword] = useState("");

  useEffect(() => {
    async function confirmAuth() {
      if (code.length === 6) {
        setLoading(true);
        try {
          const result = await auth.confirmSignUp(email, code);
          if (result === "SUCCESS") {
            await auth.signIn({ username: email, password });
            dispatch(close());
          }
        } catch (error) {
          if ((error as any).name === "CodeMismatchException") {
            setWrongCode(true);
            setCode("");
          }
        } finally {
          setLoading(false);
        }
      }
    }

    confirmAuth();
  }, [code]);

  return {
    code,
    setCode,
    wrongCode,
    setWrongCode,
    loading,
    email,
    onChangeEmail,
    password,
    onChangePassword,
    auth,
    dispatch,
  };
};

export default useVerificationCode;
