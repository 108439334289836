import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
} from "react-share";
import React from "react";

interface Props {
  pool_name: string;
  contestant_name: string;
  whatsapp_url: string;
  facebook_url: string;
  twitter_url: string;
  reddit_url: string;
}

const SocialMediaButtons = ({
                              whatsapp_url,
                              facebook_url,
                              twitter_url,
                              reddit_url,
                              pool_name,
                              contestant_name,
                            }: Props) => {
  return (
    <>
      <FacebookShareButton
        quote={`So excited for ${pool_name}, I just put my money on ${contestant_name}! Get in on the action at ${facebook_url}`}
        hashtag="#betsparket"
        style={{ paddingRight: 5 }}
        url={facebook_url}
      >
        <FacebookIcon round size={30} />
      </FacebookShareButton>
      <div className={"d-flex flex-column justify-content-center"} style={{paddingRight: "5px"}}>
        <a href={"https://api.whatsapp.com/send?text=" + whatsapp_url}
           target="_blank"
           style={{ textDecoration: "none" }}>
          <WhatsappIcon round size={30} />
        </a>
      </div>
      <RedditShareButton style={{ paddingRight: 5 }} url={reddit_url}>
        <RedditIcon round size={30} />
      </RedditShareButton>
      <TwitterShareButton style={{ paddingRight: 5 }} url={twitter_url}>
        <TwitterIcon round size={30} />
      </TwitterShareButton>
    </>
  );
};

export default SocialMediaButtons;
