import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { useContext } from "react";
import { CaretUpFill, CaretDownFill } from "react-bootstrap-icons";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

interface Props {
  children: any;
  eventKey: string;
  styles: any;
  backgroundColor: string;
}

const AccordionHeader = ({
  children,
  eventKey,
  styles,
  backgroundColor,
}: Props) => {
  const currentEventKey = useContext(AccordionContext);
  const isOpen = currentEventKey.activeEventKey === eventKey;

  const decoratedOnClick = useAccordionButton(eventKey, () => {});

  const Icon = isOpen ? CaretUpFill : CaretDownFill;
  return (
    <div
      className={styles.titleContainer}
      onClick={decoratedOnClick}
      style={{ backgroundColor }}
    >
      <BlockyBoldText className={styles.titleText}>{children}</BlockyBoldText>
      <Icon className={styles.titleText} />
    </div>
  );
};

export default AccordionHeader;
