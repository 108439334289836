import useDeviceDimensions from "hooks/useDeviceDimensions";
import { Contest } from "interfaces/leaderboard/contest";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { getRequest } from "utils/httpClient";
import { Form } from "react-bootstrap";
import { Phase } from "interfaces/leaderboard/phase";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { getContestsPath } from "utils/backend-path-builders";

interface Props {
  setContest: Dispatch<SetStateAction<any>>;
  setPhase: Dispatch<SetStateAction<Phase | undefined>>;
  phase: Phase | undefined;
}

interface Option {
  id: string;
  label: string;
}

const ContestFilter = ({ setContest, setPhase, phase }: Props) => {
  const [loading, setLoading] = useState(true);
  const [contests, setContests] = useState<Contest[]>([]);
  const [contestOptions, setContestOptions] = useState<Option[]>([]);
  const [phaseOptions, setPhaseOptions] = useState<Phase[]>([]);
  const { isMobile } = useDeviceDimensions();
  const groupId = useAppSelector(getGroupState).id;
  const [selectedContest, setSelectedContestOption] = useState<Option>();

  useEffect(() => {
    if (groupId) {
      fetchOptions();
    }
  }, [groupId]);

  const fetchOptions = () => {
    setLoading(true);
    const path = getContestsPath(groupId);
    getRequest(path, { skipIntegrationApi: true }).then((data: Contest[]) => {
      const contests = data.filter((contest: Contest) => !contest.is_deleted);
      setContests(contests);

      const options: Option[] = contests.map((contest: Contest) => ({ id: contest.id, label: contest.name }));
      setContestOptions(options);

      if (!selectedContest && options) {
        const activeContest = contests.find((contest: Contest) => contest.is_active);

        if (activeContest) {
          const selectedOption = options.find((option) => option.id === activeContest.id) as Option;
          if (selectedOption) {
            setSelectedContestOption(selectedOption);
            setContest(activeContest);
            setPhaseOptions(activeContest.phases || []);

            const activePhase = activeContest.phases?.find(phase => phase.is_active);
            if (activePhase) {
              setPhase(activePhase);
            }
          }
        }
      }
      setLoading(false);
    });
  };

  if ((loading && !selectedContest) || contestOptions.length === 0) {
    return <div></div>;
  }

  return (
    <div style={{ width: isMobile ? "100%" : "50%", paddingRight: 5, paddingBottom: 5 }}>
      <Typeahead
        id="leaderboard-event-filter"
        options={contestOptions}
        placeholder="Contest"
        onFocus={() => {
          if (contests.length === 0) {
            fetchOptions();
          }
        }}
        clearButton
        onChange={(filter) => {
          if (filter.length === 0) {
            setContest(undefined);
            setPhaseOptions([]);
            setPhase(undefined);
            return;
          }
          const contest = contests.find((c: Contest) => c.id === filter[0].id);
          setContest(contest);

          const phases = contest?.phases || [];
          setPhaseOptions(phases);
          const activePhase = phases.find(phase => phase.is_active);
          if (activePhase) {
            setPhase(activePhase);
          }
        }}
        isLoading={loading}
        defaultSelected={[selectedContest] as Option[]}
      />
      {phaseOptions.length >= 1 && (
        <div style={{ marginTop: 5, display: "flex", flexDirection: "column" }}>
          <Form>
            <Form.Check
              style={{ display: "inline-flex" }}
              inline
              type="radio"
              label={<BlockyBoldText>Overall</BlockyBoldText>}
              name="phases"
              defaultChecked={true}
              onChange={() => setPhase(undefined)}
            />
            {phaseOptions.map((option: Phase) =>
              <Form.Check
                style={{ display: "inline-flex" }}
                key={option.id}
                inline
                type="radio"
                id={option.id}
                label={<BlockyBoldText>{option.name}</BlockyBoldText>}
                name="phases"
                checked={phase?.id === option.id}
                onChange={(e) => setPhase(phaseOptions.find((p) => p.id === e.target.id))}
              />)}
          </Form>
        </div>
      )}
    </div>
  );
};

export default ContestFilter;
