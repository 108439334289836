import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import Header from "components/header";
import { useEffect, useState } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import Headroom from "react-headroom";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import { getGroupStyles } from "redux/slices/group-slice";
import {
  getConfigurableNotificationsPath,
  integratedUserNotifications,
} from "utils/backend-path-builders";
import { getRequest, putRequest } from "utils/httpClient";

interface NotificationConfigurationResponse {
  type: string;
  description: string;
}
const NotificationConfiguration = () => {
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  const [currentEmailSettings, setCurrentEmailSettings] = useState<any>({});
  const [configurableNotifications, setConfigurableNotifications] = useState<
    NotificationConfigurationResponse[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [loadingUserSettings, setLoadingUserSettings] = useState(true);

  useEffect(() => {
    getRequest(getConfigurableNotificationsPath(), { skipIntegrationApi: true })
      .then((response: NotificationConfigurationResponse[]) => {
        setConfigurableNotifications(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    getRequest(integratedUserNotifications())
      .then((json) => {
        setCurrentEmailSettings(json);
      })
      .catch((error) => error)
      .finally(() => setLoadingUserSettings(false));
  }, []);

  const updateSettings = (key: string, value: boolean) => {
    const newEmailSettings = { ...currentEmailSettings.notifications.email };
    newEmailSettings[key] = value;
    const newUser = { ...currentEmailSettings } as any;
    newUser.notifications = { email: newEmailSettings };

    putRequest(integratedUserNotifications(), { body: newUser })
      .then((response) => {
        setCurrentEmailSettings(response);
        toast.success("Notification settings updated successfully");
      })
      .catch(() => {
        toast.error("Error updating notification settings");
      });
  };

  if (loading || loadingUserSettings) {
    return (
      <div
        style={{
          color: primaryColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Spinner animation="border" />;
      </div>
    );
  }

  const emailSettings = currentEmailSettings.notifications.email;
  const doNotShowTypes = ["every_login", "account_updates", "password_reset"];
  return (
    <>
      <Headroom>
        <div>
          <Header />
        </div>
      </Headroom>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <BlockyBoldText style={{ alignSelf: "center", fontSize: 30 }}>
          Notifications
        </BlockyBoldText>
        <Table size="sm" style={{ marginLeft: 50 }}>
          <tbody>
            {configurableNotifications.map((cn) => {
              if (emailSettings[cn.type] === undefined) {
                return null;
              }
              if (doNotShowTypes.includes(cn.type)) {
                return null;
              }
              const title = cn.description;
              const value = emailSettings[cn.type] || false;
              return (
                <tr key={cn.type}>
                  <td>{title}</td>
                  <td>
                    <Form.Switch
                      type="switch"
                      id="custom-switch"
                      label="Enable"
                      checked={value}
                      onChange={(e) => {
                        updateSettings(cn.type, e.target.checked);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default NotificationConfiguration;
