import OpenSansText from "components/custom-texts/open-sans-text";
import { LineBreak } from "./highlights";
import darkStyles from "../dark-styles.module.css";

import Accordion from "react-bootstrap/Accordion";
import AccordionHeader from "../accordion/accordion-header";
const CONTENT = [
  {
    title: "Overview",
    body: [
      "We want all our customers to have fun and to enjoy our properties safely. We are committed to a policy of responsible gaming. While we recognize the overwhelming majority of our customers enjoy our various forms of gaming responsibly, we also understand that there is a small portion of the population who do not. To protect them, and others affected by their behavior, we have established a set of policies and guidelines modeled after the American Gaming Association’s Code of Conduct for Responsible Gaming. You can set limits on the amount of picks or deposits you can make in the Responsible Gaming Section of the profile page. You can also exclude yourself from prediction via the profile page or via the state specific resources below. If you need immediate assistance, please call 1-800-GAMBLER.",
    ],
  },
  {
    title: "State Specific Resources",
    body: [
      <>
        Iowa:{" "}
        <a href="https://irgc.iowa.gov/self-exclusion-program">
          https://irgc.iowa.gov/self-exclusion-program
        </a>
      </>,
      <>
        New Jersey:{" "}
        <a href="https://www.nj.gov/casinos/services/problem/">
          https://www.nj.gov/casinos/services/problem/
        </a>
      </>,
      <>
        Wyoming <a href="https://gaming.wyo.gov/">https://gaming.wyo.gov/</a>
      </>,
      <>
        California:{" "}
        <a href="https://oag.ca.gov/gambling/responsible">
          https://oag.ca.gov/gambling/responsible
        </a>
      </>,
      <>
        Ontario:{" "}
        <a href="https://about.olg.ca/responsible-gambling/">
          https://gaming.wyo.gov/https://gaming.wyo.gov/
        </a>
      </>,
      <>
        Colorado:{" "}
        <a href="https://sbg.colorado.gov/problem-gambling-resources">
          https://gaming.wyo.gov/https://gaming.wyo.gov/
        </a>
      </>,
      <>
        Oklahoma:{" "}
        <a href="https://oklahoma.gov/odmhsas/treatment/gambling.html">
          https://gaming.wyo.gov/https://gaming.wyo.gov/
        </a>
      </>,
      <>
        New York:{" "}
        <a href="https://www.ny.gov/programs/responsible-gaming">
          https://gaming.wyo.gov/https://gaming.wyo.gov/
        </a>
      </>,
    ],
  },
];

const BodyContent = ({ content }: any) => {
  return (
    <>
      {content.map((paragraph: string, index: number) => {
        const isLast = index === content.length - 1;
        return (
          <div style={{ paddingLeft: 10, paddingRight: 10 }} key={index}>
            <OpenSansText>{paragraph}</OpenSansText>
            {!isLast && <LineBreak />}
          </div>
        );
      })}
    </>
  );
};

const ResponsibleGaming = () => {
  return (
    <>
      <Accordion>
        {CONTENT.map((page, index) => {
          return (
            <div key={index}>
              <AccordionHeader
                eventKey={index.toString()}
                styles={darkStyles}
                backgroundColor="var(--sparket-black)"
              >
                {page.title}
              </AccordionHeader>
              <Accordion.Collapse eventKey={index.toString()}>
                <BodyContent content={page.body} />
              </Accordion.Collapse>
            </div>
          );
        })}
      </Accordion>
    </>
  );
};

export default ResponsibleGaming;
