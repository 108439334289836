import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { useState } from "react";
import { Form as Form, Button, Alert, Spinner } from "react-bootstrap";
import { getSelfExclusionPath } from "utils/backend-path-builders";
import { postRequest } from "utils/httpClient";
import ConfirmForm from "./confirm-form";

const SECONDS_PER_DAY = 86400;
const DAYS_PER_WEEK = 7;
const DAYS_PER_YEAR = 365;

const SECONDS_PER_WEEK = SECONDS_PER_DAY * DAYS_PER_WEEK;
const SECONDS_PER_THIRTY_DAYS = SECONDS_PER_DAY * 30;
const SECONDS_PER_YEAR = SECONDS_PER_DAY * DAYS_PER_YEAR;
const SECONDS_PER_FIVE_YEARS = SECONDS_PER_YEAR * 5;
const SECONDS_PER_ONE_HUNDRED_YEARS = SECONDS_PER_YEAR * 100;

interface Option {
  label: string;
  value: number;
}

const SELF_SUSPENSION_OPTIONS: Option[] = [
  { label: "24 hours", value: SECONDS_PER_DAY },
  { label: "1 week", value: SECONDS_PER_WEEK },
  { label: "30 days", value: SECONDS_PER_THIRTY_DAYS },
  { label: "1 year", value: SECONDS_PER_YEAR },
  { label: "5 years", value: SECONDS_PER_FIVE_YEARS },
  { label: "forever", value: SECONDS_PER_ONE_HUNDRED_YEARS },
];

interface Props {
  refreshData: () => void;
}
const SelfSuspensionsForm = ({ refreshData }: Props) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [showConfirmButtons, setShowConfirmButtons] = useState(false);
  const [saving, setSaving] = useState(false);

  const onConfirmSubmit = async () => {
    setSaving(true);
    const body = { duration: parseInt(selectedOption) };
    await postRequest(getSelfExclusionPath(), { body });
    refreshData();
    setSaving(false);
  };

  if (showConfirmButtons) {
    const SelectedLabel = SELF_SUSPENSION_OPTIONS.find(
      (option) => selectedOption === option.value.toString()
    )?.label;
    return (
      <ConfirmForm
        explanationText={`Are you sure you want to exclude yourself from all wagers for ${SelectedLabel}?`}
        onConfirm={onConfirmSubmit}
        onCancel={() => {
          setShowConfirmButtons(false);
          setSelectedOption("");
        }}
        saving={saving}
      />
    );
  }

  return (
    <>
      <BlockyBoldText>
        Exclude me from all real money gambling for:
      </BlockyBoldText>

      {SELF_SUSPENSION_OPTIONS.map((option) => {
        return (
          <Form.Check
            name="suspension-options"
            key={option.label}
            type="radio"
            label={option.label}
            id={option.value.toString()}
            onChange={(e) => {
              setSelectedOption(e.target.id);
            }}
          />
        );
      })}
      <Button
        onClick={() => {
          setShowConfirmButtons(true);
        }}
        disabled={!selectedOption}
      >
        Submit
      </Button>
    </>
  );
};

export default SelfSuspensionsForm;
