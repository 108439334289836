import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { useState } from "react";
import styles from "./styles.module.css";
import Header from "components/header";
import RequestForm from "./form";
import { IdscanVerificationRequestBody } from "./container";
import { toast } from "react-toastify";

interface Props {
  sendRequest: (data: IdscanVerificationRequestBody) => Promise<string>;
  history: any;
}

const Presenter = ({ sendRequest, history }: Props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <>
      <Header />
      <div className={styles.formWrapper}>
        <BlockyHeavyText style={{ fontSize: 20 }}>
          Identity Verification
        </BlockyHeavyText>
        <RequestForm
          {...{
            firstName,
            lastName,
            phone,
            setFirstName,
            setLastName,
            setPhone,
            sendRequest: (body: IdscanVerificationRequestBody) => {
              sendRequest(body).then(() => {
                toast.success(
                  `You should recieve a text at ${phone}. Please click the link in the text and follow the instructions to verify your identity.`
                );
                history.push("/");
              });
            },
          }}
        />
      </div>
    </>
  );
};

export default Presenter;
