import { InfoCircleFill } from "react-bootstrap-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";

const MultipleGroupsTooltip = () => {
  const group = useAppSelector(getGroupState);
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover placement="bottom" id="multiple-groups-tooltip">
          <Popover.Header as={BlockyHeavyText}>
            Don't see your bet?
          </Popover.Header>
          <Popover.Body>
            <BlockyMediumText>
              This page only shows bets from{" "}
              <span style={{ fontWeight: 700 }}>{group.name}</span>. To see your
              other bets please change groups.
            </BlockyMediumText>{" "}
          </Popover.Body>
        </Popover>
      }
    >
      <InfoCircleFill />
    </OverlayTrigger>
  );
};

export default MultipleGroupsTooltip;
