import { useAuth } from "hooks/auth";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/hooks";
import { setGroup } from "redux/slices/group-slice";
import { getGroupBySlugPath } from "utils/backend-path-builders";
import { CURRENT_GROUP_ID } from "utils/constants";
import { getRequest } from "utils/httpClient";
import * as storage from "utils/local-storage";

const SetGroupStateFromSlug = (groupSlugFromUri: string) => {
  const [groupSlug, setGroupSlug] = useState(groupSlugFromUri);
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (auth.signedIn || auth.pending) {
      return;
    }

    const localStorageGroupId = storage.local.getItem(CURRENT_GROUP_ID);

    if (!groupSlug && !groupSlugFromUri && !localStorageGroupId) {
      setGroupSlug(process.env.REACT_APP_DEFAULT_URI || "");
    }
  }, [auth, groupSlugFromUri]);

  useEffect(() => {
    if (groupSlug) {
      const path = getGroupBySlugPath(groupSlug);
      getRequest(path, { skipIntegrationApi: true })
        .then((data) => {
          if (data) {
            dispatch(setGroup(data));
          } else {
            throw `No group found for ${groupSlug}`;
          }
        })
        .catch(() => {
          toast.error(`Something went wrong. Group ${groupSlug} not found.`);
          history.push("/");
        });
    }
  }, [groupSlug]);
};

export default SetGroupStateFromSlug;
