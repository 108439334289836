import { CheckRequestData } from "@xpointtech/xpoint-js";

const CUSTOM_ERRORS: any = {
  4001: "We have detected that you are attempting to wager from outside the permitted boundary.",
};

const getCustomGeolocationErrors = (checkRequestData: CheckRequestData) => {
  if (!checkRequestData.errors) return;

  const errorCode = checkRequestData.errors[0].code;
  const customMessage = CUSTOM_ERRORS[errorCode];

  return customMessage || checkRequestData.errors[0].description;
};

export default getCustomGeolocationErrors;
