import { useState } from "react";
import { Auth } from "aws-amplify";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Button, Form } from "react-bootstrap";
import { sparketGold } from "utils/constants";
import Container from "components/profile-background-and-logo";
import { useAuth } from "hooks/auth";
import { useAppSelector } from "redux/hooks";
import { getGroupSecondaryColorOrDefault } from "redux/slices/group-slice";

const ForgotPassword = (props: any) => {
  const [email, onChangeEmail] = useState("");
  const [code, onChangeCode] = useState("");
  const [password, onChangePassword] = useState("");
  const [error, setError] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const auth = useAuth();
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  return (
    <Container>
      {emailSubmitted ? (
        <>
          <Form
            style={{
              alignItems: "center",
              width: "90%",
              maxWidth: 310,
              paddingTop: 70,
            }}
          >
            <Form.Group>
              <Form.Label>
                <BlockyBoldText customStyles={{ color: secondaryColor }}>
                  Check email for verification code
                </BlockyBoldText>
              </Form.Label>

              <Form.Control
                style={{ marginBottom: 15, borderRadius: "unset" }}
                autoComplete="new-password"
                placeholder="Verification Code"
                onChange={(e) => onChangeCode(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <BlockyBoldText customStyles={{ color: secondaryColor }}>
                  New Password
                </BlockyBoldText>
              </Form.Label>
              <Form.Control
                type="password"
                autoComplete="new-password"
                style={{ marginBottom: 15, borderRadius: "unset" }}
                placeholder="New Password"
                onChange={(e) => onChangePassword(e.target.value)}
                isInvalid={error !== ""}
              />

              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              style={{
                marginBottom: 15,
                width: "100%",
                backgroundColor: secondaryColor,
                color: "black",
                borderRadius: "unset",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (password.length < 6) {
                  setError("Password must be 6 characters or longer");
                  return;
                }
                Auth.forgotPasswordSubmit(email, code, password)
                  .then(async (data) => {
                    await auth.signIn({ username: email, password });
                    props.history.push("/");
                  })
                  .catch((err) => {
                    if ((err.code = "CodeMismatchException")) {
                      setError("Invalid verification code.");
                    } else if ((err.code = "LimitExceededException")) {
                      setError(
                        "Too many attempts. Please wait a while and try again."
                      );
                    } else {
                      setError("Invalid Password");
                    }
                  });
              }}
            >
              <BlockyBoldText>Submit</BlockyBoldText>
            </Button>
          </Form>
        </>
      ) : (
        <>
          <BlockyBoldText customStyles={{ color: secondaryColor }}>
            Forgot Password
          </BlockyBoldText>
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "90%",
              maxWidth: 310,
              paddingTop: 70,
            }}
          >
            <Form.Control
              style={{ marginBottom: 15, borderRadius: "unset" }}
              id="email"
              placeholder="Email"
              onChange={(e) => {
                onChangeEmail(e.target.value.trim());
              }}
              isInvalid={error !== ""}
            />

            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
            <Button
              style={{
                marginBottom: 15,
                width: "100%",
                backgroundColor: secondaryColor,
                color: "black",
                borderRadius: "unset",
              }}
              onClick={() => {
                Auth.forgotPassword(email)
                  .then((data) => {
                    setError("");
                    setEmailSubmitted(true);
                  })
                  .catch((err) => {
                    console.log(err);

                    if (err.code === "InvalidParameterException") {
                      setError(
                        "User is not verified. Please use this email to attempt to Sign In and enter the verification code to activate your account prior to resetting your password"
                      );
                    } else if (err.code === "UserNotFoundException") {
                      setError(
                        "User not found. Please make sure the email address you are entering signed up via email, rather than a social login. You can try signing up with this email address again. If you need further assistance reach out to support@betsparket.com"
                      );
                    } else {
                      setError(err.message);
                    }
                  });
              }}
            >
              <BlockyBoldText>Submit</BlockyBoldText>
            </Button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default ForgotPassword;
