import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { Spinner } from "react-bootstrap";
import { sparketGold } from "utils/constants";
import styles from "../badge-styles.module.css";
import { useAppSelector } from "../../../../redux/hooks";
import { getGroupSecondaryColorOrDefault } from "../../../../redux/slices/group-slice";

const WaitingBadgeContent = () => {
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);
  return (
    <div className={styles.badgeContentWrapper}>
      <Spinner size="sm" animation="grow" style={{ color: secondaryColor}} />
      <div className={styles.iconSpacing}></div>
      <BlockyMediumText>Verifying your location</BlockyMediumText>
    </div>
  );
};

export default WaitingBadgeContent;
