import "rsuite/dist/styles/rsuite-default.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setGroup, getGroupState } from "redux/slices/group-slice";
import { useEffect } from "react";
import { CURRENT_GROUP_ID, IDLE } from "utils/constants";
import {
  fetchAvailableGroups,
  getAvailableGroupsStatus,
  getAvailableGroups,
} from "redux/slices/available-groups-slice";
import { useAuth } from "hooks/auth";
import { getRequest } from "utils/httpClient";
import * as storage from "utils/local-storage";
import { getGroupDetailsPath } from "utils/backend-path-builders";

const InitializeGroupState = (path: string) => {
  const isSlugUrl = path.includes("/g/");
  const dispatch = useAppDispatch();
  const currentGroupState = useAppSelector(getGroupState);
  const availableGroupsStatus = useAppSelector(getAvailableGroupsStatus);
  const availableGroups = useAppSelector(getAvailableGroups);
  const auth = useAuth();

  useEffect(() => {
    if (!auth.signedIn) return;

    if (availableGroupsStatus === IDLE) {
      dispatch(fetchAvailableGroups());
    }
  }, [availableGroupsStatus, dispatch, auth.signedIn]);

  // 1. Look for local storage group in available groups. If found, set as current group.
  // 2. If #1 not found, fetch group data for local storage group id. If found, set as current group,
  // otherwise just set current group as first available group (this might never happen?).
  useEffect(() => {
    if (currentGroupState.id !== "" || isSlugUrl) {
      return;
    }
    const localStorageGroupId = storage.local.getItem(CURRENT_GROUP_ID);

    const currentGroup = availableGroups.find((availableGroup) => {
      return localStorageGroupId === availableGroup.id;
    });

    if (currentGroup) {
      dispatch(setGroup(currentGroup));
    } else if (localStorageGroupId) {
      const path = getGroupDetailsPath(localStorageGroupId);
      getRequest(path)
        .then((data) => {
          if (data) {
            dispatch(setGroup(data));
          } else {
            throw `Group with id ${localStorageGroupId} not found`;
          }
        })
        .catch(() => {
          storage.local.removeItem(CURRENT_GROUP_ID);
          window.location.reload();
        });
    } else if (availableGroups.length !== 0) {
      dispatch(setGroup(availableGroups[0]));
    }
  }, [availableGroups]);
};

export default InitializeGroupState;
