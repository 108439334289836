import { logoImgUrl } from "utils/constants";
const Logo = () => {
  return (
    <img
      alt=""
      src={logoImgUrl}
      style={{
        height: "30vh",
        paddingTop: "6vh",
        paddingBottom: "3vh",
        maxWidth: 400,
      }}
    />
  );
};

export default Logo;
