import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  close,
  getLoginModalsState,
  LoginModalSlice,
} from "redux/slices/login-modals-slice";
import sharedStyles from "../login-modal/styles.module.scss";
import styles from "./styles.module.scss";
import ConfirmMfaContent from "./confirm-mfa-content";
import SetupMfaContent from "./setup-mfa-content";

const getContent = (state: LoginModalSlice) => {
  if (state.setupTwoFactorOpen) {
    return SetupMfaContent;
  }

  if (state.confirmTwoFactorOpen) {
    return ConfirmMfaContent;
  }

  return false;
};

const TwoFactorModal = () => {
  const state = useAppSelector(getLoginModalsState);
  const show = state.setupTwoFactorOpen || state.confirmTwoFactorOpen;
  const dispatch = useAppDispatch();

  const Content = getContent(state);

  return (
    <Modal
      onHide={() => {
        // if logging in we shouldn't let them click out of the code input
        if (state.setupTwoFactorOpen) {
          dispatch(close());
        }
      }}
      show={show || false}
      contentClassName={sharedStyles.modalContent}
    >
      <div className={styles.modalContent}>{Content && <Content />}</div>
    </Modal>
  );
};

export default TwoFactorModal;
