import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import useWallet from "hooks/useWallet";
import styles from "./styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { useAppSelector } from "redux/hooks";
import { getGroupStyles } from "redux/slices/group-slice";
import PrizeoutButton from "./prizeout-button";
import {getIntegratedAppState} from "../../../redux/slices/integrated-app-slice";
import JoinGroupButton from "../../../home/join-group-button/join-group-button";

interface Props {
  refreshTransactionData: () => void;
}
const Balance = ({ refreshTransactionData }: Props) => {
  const { formattedBalance, balance } = useWallet();
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;

  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  return (
    <div
      style={{ backgroundColor: primaryColor }}
      className={styles.balanceRow}
    >
      <BlockyHeavyText
        customStyles={{ fontSize: 22, display: "flex", alignItems: "center" }}
      >
        Bankroll
      </BlockyHeavyText>
      <div style={{ display: "flex" }}>
        <BlockyBoldText
          customStyles={{
            display: "flex",
            alignItems: "center",
            paddingRight: 7,
          }}
        >
          Balance: {formattedBalance}
        </BlockyBoldText>
        <PrizeoutButton
          balance={balance}
          refreshTransactionData={refreshTransactionData}
        />
      </div>
    </div>
  );
};

export default Balance;
