import { Button, Alert, Spinner } from "react-bootstrap";

interface Props {
  explanationText: string;
  onConfirm: () => {};
  onCancel: () => void;
  saving: boolean;
}

const ConfirmForm = ({
  explanationText,
  onConfirm,
  onCancel,
  saving,
}: Props) => {
  return (
    <div style={{ maxWidth: 500 }}>
      <Alert variant="danger">
        {explanationText}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 30,
            paddingTop: 10,
          }}
        >
          <Button disabled={saving} variant="link" onClick={onCancel}>
            Cancel
          </Button>
          <Button style={{ width: 100 }} disabled={saving} onClick={onConfirm}>
            {saving ? (
              <Spinner style={{ height: 20, width: 20 }} animation="border" />
            ) : (
              "Confirm"
            )}
          </Button>
        </div>
      </Alert>
    </div>
  );
};

export default ConfirmForm;
