import { Modal } from "react-bootstrap";
import SignInContent from "components/sign-in/form";
import { sparketGold, sparketBlack } from "utils/constants";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  close,
  getLoginModalsState,
  LoginModalSlice,
} from "redux/slices/login-modals-slice";
import styles from "./styles.module.scss";
import SignUpContent from "components/sign-up/form";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { ReactComponent as SparketLogo } from "icons/sparket-logo.svg";
import Logo from "icons/sparket-social-betwork-logo.png";
import { getGroupSecondaryColorOrDefault } from "../../../redux/slices/group-slice";

const getContent = (state: LoginModalSlice) => {
  if (state.signUpOpen) {
    return SignUpContent;
  }

  if (state.signInOpen) {
    return SignInContent;
  }

  return false;
};

const LoginModal = () => {
  const state = useAppSelector(getLoginModalsState);
  const show = state.signInOpen || state.signUpOpen;
  const dispatch = useAppDispatch();
  const Content = getContent(state);
  const [allowClickOutside, setAllowClickOutside] = useState(true);
  const [showConfirmationForm, setShowConfirmationForm] = useState(false);
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  useEffect(() => {
    setShowConfirmationForm(false);
  }, [show]);

  return (
    <Modal
      onHide={() => {
        if (allowClickOutside) {
          dispatch(close());
        }
      }}
      show={show}
      contentClassName={styles.modalContent}
    >
      <div className={styles.column}>
        <div
          className={styles.column}
          style={{
            backgroundSize: "contain",
            alignItems: "center",
          }}
        >
          <BlockyMediumText style={{ color: secondaryColor }}>
            You must be logged in to make a bet
          </BlockyMediumText>
          {!allowClickOutside && (
            <div
              style={{
                marginTop: 17,
                width: "100%",
                marginLeft: 117,
                marginBottom: -20,
                zIndex: 100000,
              }}
            >
              <Button
                style={{
                  backgroundColor: secondaryColor,
                  borderColor: secondaryColor,
                  color: sparketBlack,
                  borderRadius: 5,
                  marginBottom: 5,
                }}
                onClick={() => {
                  setShowConfirmationForm(false);
                }}
              >
                <BlockyHeavyText customStyles={{ fontSize: 14 }}>
                  &lt; Back
                </BlockyHeavyText>
              </Button>
            </div>
          )}
          <img
            alt=""
            src={Logo}
            style={{
              height: "25vh",
            }}
          />
          {Content && (
            <Content
              showConfirmationForm={showConfirmationForm}
              setShowConfirmationForm={setShowConfirmationForm}
              setAllowClickOutside={setAllowClickOutside}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
