import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { Table } from "react-bootstrap";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import {
  getExternalIdModalState,
  openExternalIdModal,
} from "redux/slices/external-id-modal-slice";
import { useEffect, useState } from "react";
import { getRequest } from "utils/httpClient";
import { ExternalIdsResponse } from "interfaces/external-ids-response";
import { SUCCEEDED } from "utils/constants";
import { getUsersExternalIdsPath } from "utils/backend-path-builders";

const GroupDetails = () => {
  const group = useAppSelector(getGroupState);
  const dispatch = useAppDispatch();
  const [externalId, setExternalId] = useState("");
  const [loading, setLoading] = useState(true);
  const externalIdModalState = useAppSelector(getExternalIdModalState);
  const externalIdLabel = group.settings.external_id_label;

  useEffect(() => {
    if (loading) {
      getRequest(getUsersExternalIdsPath()).then(
        (res: ExternalIdsResponse[]) => {
          const externalIdValue =
            res.find((item) => item.group_id === group.id)?.external_id || "";

          setExternalId(externalIdValue);
          setLoading(false);
        }
      );
    }
  }, [group, loading]);

  useEffect(() => {
    if (externalIdModalState.updatedStatus === SUCCEEDED) {
      setLoading(true);
    }
  }, [externalIdModalState.updatedStatus]);

  if (loading) {
    return <div />;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <BlockyBoldText>{group.name} Info</BlockyBoldText>
      <Table size="sm">
        <tbody>
          <tr>
            <td>{externalIdLabel}</td>
            <td>{externalId}</td>
            <td>
              <button onClick={() => dispatch(openExternalIdModal())}>
                <BlockyHeavyText>
                  {externalId === "" ? `Add ${externalIdLabel}` : "Edit"}
                </BlockyHeavyText>
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default GroupDetails;
