import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "utils/constants";
import { postRequest } from "utils/httpClient";
import { RootState } from "../store";
import { getUsersExternalIdPath } from "utils/backend-path-builders";

interface ExternalIdModalState {
  open: boolean;
  updatedStatus: "idle" | "loading" | "succeeded" | "failed";
  failMessage: string | null;
}

const initialState: ExternalIdModalState = {
  open: false,
  updatedStatus: IDLE,
  failMessage: null,
};

export const updateExternalId = createAsyncThunk(
  "externalIdModal/updateExternalId",
  async ({ groupId, externalId }: any, { rejectWithValue }) => {
    try {
      const response = await postRequest(getUsersExternalIdPath(), {
        body: {
          group_id: groupId,
          external_id: externalId,
        },
      });
      return response;
    } catch (error: any) {
      const technical_error = error.response.data.technical_error;
      if (technical_error.includes("duplicate key value")) {
        return rejectWithValue("duplicate key");
      }
    }
  }
);

const slice = createSlice({
  name: "externalIdModal",
  initialState,
  reducers: {
    openExternalIdModal: (state) => {
      state.open = true;
      state.updatedStatus = IDLE;
    },
    close: (state) => {
      state.open = false;
      state.failMessage = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(updateExternalId.pending, (state, action) => {
      state.updatedStatus = LOADING;
    });
    builder.addCase(updateExternalId.fulfilled, (state, action) => {
      state.open = false;
      state.updatedStatus = SUCCEEDED;
    });
    builder.addCase(updateExternalId.rejected, (state, action) => {
      if (action.payload === "duplicate key") {
        state.failMessage =
          "The value you have entered has already been taken by another user. Please contact support@betsparket.com.";
      } else {
        state.failMessage =
          "Something went wrong. Please make sure you are entering a valid number and try again";
      }
      state.updatedStatus = FAILED;
    });
  },
});

export const { openExternalIdModal, close } = slice.actions;

export const getExternalIdModalState = (state: RootState) => {
  return state.externalIdModal;
};

export default slice.reducer;
