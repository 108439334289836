import "./drawer-styles.css";
import DrawerSection from "components/drawer/drawer-section";
import { useAuth } from "hooks/auth";
import { useAppSelector } from "redux/hooks";
import { getGroupState, getGroupStatus } from "redux/slices/group-slice";
import { APPROVED } from "utils/constants";
import { isIntegratedApp } from "utils/httpClient";

const NavigationLinks = () => {
  const { signedIn } = useAuth();
  const approvedGroupStatus = useAppSelector(getGroupStatus) === APPROVED;
  const {
    bankroll_page: bankrollPage,
    settings_page: settingsPage,
    help_page: helpPage,
    confirm_results: confirmResults,
  } = useAppSelector(getGroupState).customization;
  return (
    <>
      <div>
        <DrawerSection icon="home" label="Home" path="/" />
        {bankrollPage && approvedGroupStatus && (
          <DrawerSection icon="bankroll" label="Bankroll" path="/bankroll" />
        )}
        {signedIn && (
          <DrawerSection icon="mybets" label="My Picks" path="/mypicks" />
        )}
        <DrawerSection
          icon="leaderboard"
          label="Leaderboard"
          path="/leaderboard"
        />
        {approvedGroupStatus && confirmResults && (
          <DrawerSection
            icon="confirm"
            label="Confirm Results"
            path="/confirm-results"
          />
        )}
        {helpPage && <DrawerSection icon="help" label="Help" path="/help" />}
        {isIntegratedApp() && settingsPage && (
          <DrawerSection
            icon="settings"
            label="Settings"
            path="/notification-settings"
          />
        )}
      </div>
    </>
  );
};
export default NavigationLinks;
