import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { getRequest } from "utils/httpClient";
import SubmitAvatar from "./submitAvatar";
import defaultImg from "assets/profile-img-upload.png";
import { Spinner } from "react-bootstrap";
import { getUserPath } from "utils/backend-path-builders";

const ImageUpload = () => {
  const [imgSrc, setImgSrc] = useState(defaultImg);
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setLoading(true);
      const url = URL.createObjectURL(acceptedFiles[0]);
      setImgSrc(url);
      SubmitAvatar(acceptedFiles[0]).then(() => {
        setLoading(false);
      });
    },
  });
  useEffect(() => {
    setLoading(true);
    getRequest(getUserPath())
      .then((data) => {
        if (data.avatar) {
          setImgSrc(data.avatar);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const sizeStyles = {
    width: 100,
    height: 100,
    margin: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div {...getRootProps()}>
      {loading ? (
        <div
          style={{
            ...sizeStyles,
          }}
        >
          <Spinner animation="grow" style={{ color: "var(--sparket-gold)" }} />
        </div>
      ) : (
        <>
          <input {...getInputProps()} />
          <img
            alt=""
            style={{
              cursor: "pointer",
              ...sizeStyles,
            }}
            src={imgSrc}
          ></img>
        </>
      )}
    </div>
  );
};

export default ImageUpload;
