import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQueryParam = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const queryParamsToObject = (queryParams: URLSearchParams) => {
  return Object.fromEntries(queryParams.entries());
};

export default useQueryParam;
