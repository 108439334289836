import "rsuite/dist/styles/rsuite-default.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, fetchGroupStatus } from "redux/slices/group-slice";
import { useEffect } from "react";
import { useAuth } from "hooks/auth";
import { PENDING_IDENTITY_VERIFICATION } from "utils/constants";
import { useLocation } from "react-router-dom";
import { ID_VERIFICATION_REDIRECT_PATH } from "utils/constants";

const ONE_SECOND_MILLIS = 1000;
const FIVE_SECONDS_MILLIS = ONE_SECOND_MILLIS * 5;
const THIRTY_SECONDS_MILLIS = ONE_SECOND_MILLIS * 30;

const CheckGroupStatus = () => {
  const group = useAppSelector(getGroupState);
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const location = useLocation();

  const checkStatus = () => {
    dispatch(fetchGroupStatus(group.id));
  };

  useEffect(() => {
    if (group.id && auth.signedIn) {
      checkStatus();
    }
  }, [group.id, auth.signedIn]);

  // after identity verification, we check after 5 seconds for results, and then again after 30 if
  // status is still `pending_identity_verification`. Without this user needs to refresh the page to check
  // for results.
  useEffect(() => {
    if (
      location.pathname === ID_VERIFICATION_REDIRECT_PATH &&
      group.status === PENDING_IDENTITY_VERIFICATION
    ) {
      setTimeout(checkStatus, FIVE_SECONDS_MILLIS);
      setTimeout(checkStatus, THIRTY_SECONDS_MILLIS);
    }
  }, [group.status]);
};

export default CheckGroupStatus;
